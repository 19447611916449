import React from "react";
import { useState } from "react";
import OtpInput from "react-otp-input";
import {
  CustomTextInput480Width,
  H1,
  PrimaryCta,
  Image,
  CustomText,
  UnderLinedLinkCta,
} from "../../components";
import Utils from "../../common/utils.js";
import validator from "validator";
import { Link, useNavigate, useParams } from "react-router-dom";
import Core from "../../common/clientSdk";
import { toast } from "react-hot-toast";
import { useTheme } from "styled-components";
// import { useParams } from "react-router-dom";
import { useSnackbar } from "react-simple-snackbar";
import LeftSideSignup from "./leftSideSignup.js";
import NewLoginLeftSide from "./newLoginLeftSide.js";
var email, request_id;
const VerifyEmail = function (props) {
  const [loading, set_loading] = useState(false);
  const [error, setError] = useState(null);
  const [otp, setOtp] = useState("");
  const [requestId, setRequestId] = useState("");
  const [openSnackbar, closeSnackbar] = useSnackbar();
  var navigate = useNavigate();

  const userData = useParams();
  // const { email = {} } = useParams.email;
  let search = window.location.search;
  let params = new URLSearchParams(search);
  email = params.get("email");
  request_id = params.get("request_id");

  /********************************************************************
  Forgot password

  @param {Function} progress_cb - manage status of screen

  No return
  *********************************************************************/
  function postVerifyForgotPassword() {
    var params = {
      request_id: requestId ? requestId : request_id,
      verification_code: otp,
    };

    Core.postVerifyForgotPassword(
      postVerifyForgotPasswordSuccess,
      postVerifyForgotPasswordFailed,
      (status) => {
        set_loading(status);
      },
      params
    );
  }

  function postVerifyForgotPasswordSuccess(response_data) {
    const success = "New password sent to your email";
    openSnackbar(success);
    navigate("/login");
  }

  function postVerifyForgotPasswordFailed(errorList) {
    openSnackbar(errorList);
  }

  function forgotPassword(progress_cb) {
    var params = {
      email: email,
    };

    Core.postForgotPassword(
      forgotPasswordSuccess,
      forgotPasswordFailed,
      (status) => {
        set_loading(status);
      },
      params
    );
  }

  function forgotPasswordSuccess(response_data) {
    setRequestId(response_data.response_data.request_id);
    openSnackbar("Send Otp Your Email");
  }

  function forgotPasswordFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  /********************************************************************
  Screen Progress

  @param {Boolean} status - true/false

  No return
  *********************************************************************/
  function updateProgress(status) {
    set_loading(status);
  }

  // function showError(message) {
  //   toast.error(message);
  // }

  const sendOtp = (otp) => {
    setOtp(otp);
  };

  return (
    <div className="main-container-otpscreen">
      <div className="forgot-password-left-side">
        <NewLoginLeftSide />
      </div>
      <div className="forgot-password-right-side">
        <div style={{ justifyContent: "center", alignItems: "center" }}>
          <Image source={"../assets/images/Reelax.png"} />
        </div>
        {email && (
          <div className="otpscreen-left-bottom-container">
            <div className="inner-left-container-otp">
              <div className="inner-left-container-bind">
                <div className="flex-row">
                  <Image
                    style={{
                      position: "relative",
                      bottom: 32,
                      left: 11,
                    }}
                    source={"../assets/images/Vector.png"}
                    height={15}
                    width={10}
                  />

                  <CustomText
                    h1
                    fontSize={"font_size_h1"}
                    text={`Verify your email`}
                    textColor={"text_color_light"}
                    fontWeight={"rubik_regular"}
                  />
                </div>

                <div className="m-t-8">
                  <div className="flex-col-start">
                    <div
                      style={{
                        textAlign: "center",
                        minWidth: "100%",
                        marginBottom: "20px",
                      }}>
                      <CustomText
                        h1
                        fontSize={"font_size_h1"}
                        text={`Submit OTP`}
                        textColor={"text_color_primary"}
                        fontWeight={"rubik_regular"}
                      />
                    </div>
                    <div
                      className="m-t-8"
                      style={{
                        Width: "100%",
                        textAlign: "center",
                        marginBottom: "10px",
                      }}>
                      <CustomText
                        p
                        fontSize={"font_size_small"}
                        text={`We sent an OTP to your provided email, on verification you will recieve new password on your email`}
                        textColor={"text_color_primary"}
                        fontWeight={"rubik_regular"}
                      />
                    </div>

                    <div className="m-t-16 forgot-password-textfield">
                      <CustomTextInput480Width
                        placeholder="Enter OTP"
                        // value={otp}
                        onChange={(e) => sendOtp(e)}
                      />
                    </div>

                    <div className="m-t-32 forgot-password-textfield">
                      <PrimaryCta
                        className="forgot-password-button"
                        fontSize={"font_size_large"}
                        fullWidth={true}
                        text={"Enter OTP"}
                        disabled={Utils.isNullOrUndefined(otp)} // || !sent_notification
                        isLoading={loading}
                        onClick={() => {
                          postVerifyForgotPassword(updateProgress);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default VerifyEmail;
