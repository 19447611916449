import React, { useState, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import {
  CustomText,
  Image,
  CustomTextInput,
  PrimaryCta,
  LinkCta,
  UnderLinedLinkCta,
} from "../../components";
import Header from "../header";
const CreditsPolicyScreen = function (props) {
  return (
    <div>
      <Header/>
    <div className="main-container " style={{paddingTop:"70px"}}>
      <div className="text-screen-container">
        <h1><center>Reelax Credit Usage Terms</center></h1>
        <p style={{marginTop:"30px"}}>
        Reelax operates on a credit-based system to ensure flexibility and transparency in utilizing our platform’s features. Below are the terms for credit usage:
        </p>
        <h3>1. Campaign Creation</h3>
        <div style={{paddingLeft:"40px"}}>
        <p style={{marginTop:"10px"}}>
          <ul type="circle">
            <li >
              <b>Credits Charged:</b> 1 Credit
           </li>
            <li>
               <b>Description:</b> Creating a campaign will deduct 1 credit from your account. The campaign will remain online in the marketplace for 1 month, accessible to influencers.
          </li>
          </ul>
        </p>
        </div>
        <h3>2. Campaign Renewal</h3>
        <div style={{paddingLeft:"40px"}}>
        <p style={{marginTop:"10px"}}>
          <ul type="circle">
            <li >
            <b>Credits Charged:</b> 1 Credit
           </li>
            <li>
            <b>Description:</b> Renewing an existing campaign will deduct 1 credit. The campaign will remain live in the marketplace for an additional 1 month.
          </li>
          </ul>
        </p>
        </div>
        <h3>3. Request Price</h3>
        <div style={{paddingLeft:"40px"}}>
        <p style={{marginTop:"10px"}}>
          <ul type="circle">
            <li >
            <b>Credits Charged:</b> 1 Credit
           </li>
            <li>
            <b>Description:</b> When you request a price from an influencer, 1 credit will be deducted. This allows the influencer to receive the campaign invite and provide their asking price.
          </li>
          </ul>
        </p>
        </div>
        <h3>4. Counter Offer</h3>
        <div style={{paddingLeft:"40px"}}>
        <p style={{marginTop:"10px"}}>
          <ul type="circle">
            <li >
            <b>Credits Charged:</b> 1 Credit
           </li>
            <li>
            <b>Description:</b> Submitting a counteroffer to an influencer will deduct 1 credit. The influencer will either accept or reject the counteroffer, facilitating smooth negotiations.
          </li>
          </ul>
        </p>
        </div>
        <div style={{marginTop:"30px"}}>By adhering to these credit usage terms, you can maximize the value of your campaigns and interactions on Reelax.</div>
      </div>
    </div>
    </div>
  );
};
export default CreditsPolicyScreen;
