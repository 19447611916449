import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import { Rating } from "react-simple-star-rating";
import Theme from "../../resources/themes/light.js";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { InstagramEmbed, YouTubeEmbed, XEmbed } from "react-social-media-embed";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  CustomText,
  H1,
  LinkCta,
  Image,
  PrimaryCta,
  CustomButton,
  CustomTextInput,
  CustomTextArea,
} from "../core_component/index";
import Styles from "./style.js";
import Icon from "../core_component/icon";
import Modal from "react-modal";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Utils from "../../common/utils.js";
import { ToastContainer, toast } from "react-toastify";
import { isMobile } from "react-device-detect";

export const AnyComponent = function (props) {
  return <H1 />;
};

export const Menubar = function (props) {
  return (
    <ul>
      {
        // window.log(props.data)
        props.data.map((items) => (
          <Link to={items.url}>{items.title}</Link>
        ))
      }
    </ul>
  );
};

export const TopHeading = function (props) {
  return (
    <div
      style={{
        marginTop: "22px",
        letterSpacing: " 0.08px",
        display: "flex",
        flexDirection: "row",
      }}>
      <CustomText
        p
        text={props.title}
        fontSize={"font_size_large"}
        textAlign={"text_align_left"}
        textColor={"text_color_primary"}
      />
      &nbsp;
      <LinkCta text={props.linkText} />
    </div>
  );
};

export const CustomCheckbox = function (props) {
  const [selected, set_selected] = useState(props.selected);

  // var input_type;
  // if(props.inputType == 'checkbox'){
  //   input_type = 'checkbox'
  // }else{
  //   return
  // }

  return (
    <button
      style={
        selected
          ? Styles.selectedCheckBoxContainer
          : Styles.nonSelectedCheckBoxContainer
      }
      onClick={() => {
        set_selected(!selected);
        props.onClick(!selected);
      }}>
      <div>
        <label
          style={
            selected ? Styles.nonSelectedcheckboxTitle : Styles.checkboxTitle
          }
          for={props.title}>
          {props.title}
        </label>
      </div>
      {/*
        selected ?
        <div>
          <input
            type= {input_type}
            id={props.title}
            checked={selected}
            onChange={props.onChange()}
            />
        </div>
        :
        null
      */}
    </button>
  );
};

export const FallBack = function (props) {
  return (
    <div style={{ textAlign: "center", alignItems: "center", maxWidth: 600 }}>
      <Icon iconName={"FallBackIcon"} width={380} height={205} />
      <div className="flex-column" style={{ padding: 20 }}>
        <CustomText
          h1
          fontSize={"font_size_h1"}
          text={props.heading}
          textColor={"text_color_primary"}
        />
        <div className="m-t-12">
          <CustomText
            p
            fontSize={"font_size_p"}
            text={props.title}
            textColor={"text_color_secondary"}
          />
        </div>

        <CustomText
          p
          fontSize={"font_size_large"}
          text={props.subTitle}
          textColor={"text_color_secondary"}
        />
      </div>
    </div>
  );
};

export const FallBackBtn = function (props) {
  return (
    <div style={{ textAlign: "center", alignItems: "center" }}>
      <Icon
        iconName={"FallBackSmallIcon"}
        width={props.widthIcon}
        height={props.heightIcon}
      />
      <div className="flex-column" style={{ padding: 20 }}>
        <CustomText
          h1
          fontSize={"font_size_xl"}
          text={props.heading}
          textColor={"text_color_primary"}
        />
        <div style={{ marginTop: 10 }}>
          <CustomText
            p
            fontSize={"font_size_small"}
            text={props.title}
            textColor={"text_color_secondary"}
          />
        </div>

        <CustomText
          p
          fontSize={"font_size_large"}
          text={props.subTitle}
          textColor={"text_color_secondary"}
        />
      </div>
      {props.text && (
        <div className="">
          <PrimaryCta
            fifth
            onClick={props.onClick}
            fontSize={"font_size_large"}
            text={props.text}
            isLoading={props.isLoading}
          />
        </div>
      )}
    </div>
  );
};

export const SearchFallBack = function (props) {
  return (
    <div style={{ textAlign: "center", alignItems: "center" }}>
      <Icon iconName={"SearchFallBackIcon"} width={380} height={205} />
      <div className="flex-column" style={{ padding: 20 }}>
        <CustomText
          h1
          fontSize={"font_size_h1"}
          text={props.heading}
          textColor={"text_color_primary"}
        />
        <CustomText
          p
          fontSize={"font_size_p"}
          text={props.title}
          textColor={"text_color_secondary"}
        />
        <CustomText
          p
          fontSize={"font_size_large"}
          text={props.subTitle}
          textColor={"text_color_secondary"}
        />
      </div>
    </div>
  );
};

export const ModalComponent = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      style={props.customStyle}
      contentLabel="Modal">
      {props.children}
    </Modal>
  );
};

export const SocialMediaCards = function (props) {
  // const [tofuIsReady, setTofuIsReady] = useState(false);
  // const handleTofuChange = () => {
  //   setTofuIsReady(!tofuIsReady);
  // };
  return (
    <div className="social-media-container">
      <div className="instagram">
        <Icon iconName={props.iconName} height={32} width={32} />
        <div className="social-platform-name">
          <CustomText
            p
            text={props.text}
            textColor={"text_color_primary"}
            fontSize={"font_size_medium"}
            fontWeight={"Poppins_semiBold"}
          />
        </div>
      </div>
      <div>
        {/* <div className="flex-row">
          <label>
            <Toggle
              className="custom-toggle"
              defaultChecked={props.defaultChecked}
              icons={false}
              onChange={props.onChange}
            />
          </label>
        </div> */}
      </div>
    </div>
  );
};

export const CustomCheckBoxCircular = function (props) {
  const [colorText, setColorText] = useState("#667085");
  const [active, setActive] = useState(false);
  function handelTextColor() {
    setActive(!active);
    setColorText(active ? "#667085" : "#090A14");
  }

  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = () => {
    handelTextColor();
  };

  const generateUniqueId = () => {
    return (Math.random() * 1000).toFixed(0);
  };
  let uniqueId = generateUniqueId();
  return (
    <label className="" htmlFor={"checkbox-" + uniqueId}>
      <div className="social-media-post">
        <div style={{ color: colorText }}>
          <div className="social-media-post-name">
            <CustomText
              p
              text={props.text}
              textColor={"text_color_primary"}
              fontSize={"font_size_small"}
              fontWeight={"Poppins_regular"}
            />
          </div>
          <div className="social-media-post-name">
            <CustomText
              p
              text={props.description}
              textColor={"text_color_primary"}
              fontSize={"font_size_extraSmall"}
              fontWeight={"Poppins_regular"}
            />
          </div>
          {/* <InstagramVideoUpload text={props.text} /> */}
        </div>
        <div onClick={props.onClick} className="custom-checkbox">
          <input
            name={uniqueId}
            type="checkbox"
            id={"checkbox-" + uniqueId}
            checked={props.checked}
            active={colorText}
            onChange={(event) => {
              handleCheckboxChange();
              props.handleCheckbox(event);
            }}
          />
          <span
            htmlFor={"checkbox-" + uniqueId}
            className={props.checked ? "checked" : ""}></span>
        </div>
      </div>
    </label>
  );
};

export const ProposalsList1 = function (props) {
  const [showFull, setShowFull] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [rating, setRating] = useState(4.5);

  const updateProgress = (status) => {
    setLoading(status);
  };
  const SeeMoreParagraph = ({ text, charLimit = 180 }) => {
    const toggleShowFull = () => {
      setShowFull(!showFull);
    };

    if (text) {
      return (
        <div className="proposal-text">
          {showFull ? text : text.slice(0, charLimit)}
          {text.length > charLimit && (
            <span className="proposal-see-more" onClick={toggleShowFull}>
              {showFull ? " See Less" : "... See More"}
            </span>
          )}
        </div>
      );
    }
  };

  function handleRating() {
    setRating(rating);
  }

  return (
    <div style={Styles.proposalsListContainer}>
      <div className="proposal-inner-conatiner">
        <div onClick={props.onClick} className="">
          <Image
            source={props.source}
            fallBack={props.fallBack}
            className="proposal-user-profile"
          />
        </div>

        <div className="">
          <div className="proposal-heading-container">
            <CustomText
              p
              text={props.mainTitle}
              fontSize={"font_size_large"}
              textColor={"text_color_light"}
            />
            <CustomText
              p
              text={props.rating}
              fontSize={"font_size_regular"}
              textColor={"text_color_light"}
            />
          </div>

          <div className="proposal-product-container">
            <CustomText
              p
              text={props.type1}
              fontSize={"font_size_regular"}
              textColor={"text_color_light"}
            />
            <CustomText
              p
              text={props.line1}
              fontSize={"font_size_regular"}
              textColor={"text_color_light"}
            />

            <CustomText
              p
              text={props.type2}
              fontSize={"font_size_regular"}
              textColor={"text_color_light"}
            />
            <CustomText
              p
              text={props.line2}
              fontSize={"font_size_regular"}
              textColor={"text_color_light"}
            />

            <CustomText
              p
              text={props.type3}
              fontSize={"font_size_regular"}
              textColor={"text_color_light"}
            />
          </div>
          <div>
            <CustomText
              p
              text={props.boost}
              fontSize={"font_size_regular"}
              textColor={"text_completed_color"}
            />
          </div>
          <div>
            <CustomText
              p
              text={props.followers}
              fontSize={"font_size_regular"}
              textColor={"text_color_light"}
            />
          </div>

          <div>
            <SeeMoreParagraph text={props.longParagraph} />
          </div>
        </div>
      </div>
      <div className="propasal-amout-container">
        <CustomText h3 text={props.Amount} textColor={"text_color_light"} />
        <CustomText
          p
          fontSize={"font_size_small"}
          text={props.bidTitle}
          textColor={"text_light_weight_color"}
        />
        <div className="proposal-accept-button">
          <PrimaryCta
            sixth
            onClick={() => props.onApprove(updateProgress)}
            fontSize={"font_size_large"}
            text={"Accept"}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};
export const ProposalsList = function (props) {
  const [showFull, setShowFull] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [rating, setRating] = useState(4.5);
  const language = props?.language?.toString() || "";
  const updateProgress = (status) => {
    setLoading(status);
  };
  const { bio = "" } = props;
  const maxLength = 85;

  let displayText = bio.slice(0, maxLength);
  if (bio.length > maxLength) {
    displayText += "...";
  }

  return (
    <div style={Styles.proposalsListContainer}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Image
            source={props.source}
            fallBack={props.fallBack}
            className="proposal-user-profile"
            onClick={props.onClick}
          />
          <div style={{ marginLeft: 8 }}>
            <div className="flex-row align-center">
              <div className="nameWidth">
                <CustomText
                  h5
                  ellipsis
                  text={Utils.truncateWithEllipsis(props.name, 15)}
                  fontSize={"font_size_large"}
                  textColor={"text_color_black"}
                  fontWeight={"Inter_semiBold"}
                  textAlign={"text_align_left"}
                />
              </div>

              {props.Boost && (
                <div className="circle-boost">
                  <CustomText p text={"⚡"} fontSize={"font_size_label"} />
                </div>
              )}
            </div>
            <div className="flex-row">
              <Rating
                initialValue={props.onPointerEnter}
                size={16}
                readonly={true}
              />
              <div className="m-l-8">
                <CustomText
                  p
                  text={props.onPointerEnter}
                  fontSize={"font_size_small"}
                  textColor={"text_color_primary"}
                  textAlign={"text_align_left"}
                  fontWeight={"Inter_Medium"}
                />
              </div>
            </div>

            <div style={{ marginTop: 1 }}>
              <CustomText
                p
                text={props.location}
                fontSize={"font_size_label"}
                textColor={"text_color_black"}
                textAlign={"text_align_left"}
              />
            </div>
            <div className="m-t-2">
              <CustomText
                p
                text={language.charAt(0).toUpperCase() + language.slice(1)}
                fontSize={"font_size_label"}
                textColor={"text_color_black"}
              />
            </div>
          </div>
        </div>

        <div className="amountCard" style={{ alignSelf: "flex-start" }}>
          <CustomText
            p
            text={props.amount}
            fontSize={"font_size_regular"}
            textColor={"text_color_primary"}
          />
        </div>
      </div>
      {/* influencer category */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignContent: "center",
        }}>
        <CategoryNames categorys_data={props.category} bidId={props.bidId} />
      </div>

      {/* Influencer SocialMedia */}
      <div>
        <div
          style={{ display: "flex", flexDirection: "row" }}
          className="m-b-8">
          <div>
            <CustomText
              p
              text={"Total Followers"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"Inter_regular"}
            />
          </div>
          <div style={{ marginLeft: 4 }}>
            <CustomText
              p
              text={props.tolalFollowers == 0 ? "-" : props.tolalFollowers}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"Inter_SemiBold"}
            />
          </div>
        </div>
        {!props.socialMedia ? "" : props.socialMedia}
      </div>
      {/* Influencer Bio */}
      <div style={{ height: 112 }}>
        {bio && (
          <CustomText
            p
            text={displayText}
            fontSize={"font_size_small"}
            textColor={"text_color_black"}
            fontWeight={"Poppins_regular"}
          />
        )}
      </div>

      <div
        style={{
          position: "absolute",
          bottom: 24,
          zIndex: 1,
          marginTop: 16,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "calc(100% - 49px)",
        }}>
        <CustomButton
          onClick={props.onClickReject}
          fontSize={"font_size_large"}
          text={"Reject"}
          isLoading={isLoading}
          width={140}
          borderRadius={4}
          padding={{ top: 6, right: 18, bottom: 6, left: 18 }}
          textColor={"var(--gray-gray-200)"}
          background={"#FFFFFF"}
          border={"1px solid #b3b3b6"}
          boxShadow={"none"}
        />
        <CustomButton
          onClick={props.onClickApprove}
          fontSize={"font_size_large"}
          text={"Accept"}
          isLoading={isLoading}
          width={140}
          borderRadius={4}
          padding={{ top: 6, right: 18, bottom: 6, left: 18 }}
          textColor={"text_color_light"}
          background={"#090A14"}
          border={"none"}
          boxShadow={"none"}
          hoverColor={"var(--hover-color, #1977F2)"}
        />
      </div>
    </div>
  );
};

export const TeamMembers = function (props) {
  return (
    <div style={Styles.TeamMembersMainContainer}>
      <div style={Styles.TeamMembersInnerContainer}>
        <div
          style={
            props.invert ? Styles.CompanyProfile : Styles.CompanyProfileHide
          }>
          <Image
            source={props.source}
            fallBack={props.fallBack}
            className="team-member-profile-pic"
          />
        </div>
        <div>
          <CustomText
            p
            text={props.Name}
            textColor={"text_color_primary"}
            fontSize={"font_size_large"}
          />
          <CustomText
            p
            text={props.title}
            textColor={"text_color_secondary"}
            fontSize={"font_size_medium"}
          />
          <CustomText
            p
            noOfLine={"twoLines"}
            text={props.Email}
            textColor={"text_color_secondary"}
            fontSize={"font_size_paragraph"}
          />
        </div>
      </div>
      <div>
        <div>
          <CustomText p text={props.state} textColor={props.stateColor} />
        </div>
        {props.iconName && (
          <div onClick={props.onClick} className="team-members-delete-icon">
            <Icon iconName={props.iconName} height={20} width={20} />
          </div>
        )}
      </div>
    </div>
  );
};

export const TransitionHistory = function (props) {
  return (
    <div style={Styles.TeamMembersMainContainer}>
      <div>
        <CustomText
          p
          text={props.Detail}
          textColor={"text_color_secondary"}
          fontSize={"font_size_large"}
        />
        <CustomText
          p
          text={props.type}
          textColor={"text_color_secondary"}
          fontSize={"font_size_large"}
        />
        <div className="flex-row">
          <CustomText
            p
            text={props.id_title}
            textColor={"text_color_primary"}
            fontSize={"font_size_medium"}
          />
          &nbsp;
          <CustomText
            p
            text={props.Id}
            textColor={"text_light_weight_color"}
            fontSize={"font_size_medium"}
          />
        </div>
        <div onClick={props.onClick} className="flex-row">
          <CustomText
            p
            text={props.influencerTitle}
            textColor={"text_color_primary"}
            fontSize={"font_size_medium"}
          />
          &nbsp;
          <CustomText
            p
            text={props.influencerId}
            textColor={"text_light_weight_color"}
            fontSize={"font_size_medium"}
          />
        </div>
      </div>

      <div className="flex-row">
        <CustomText p text={props.sign} textColor={props.signColor} />
        <CustomText p text={props.Amount} textColor={props.AmountColor} />
      </div>
    </div>
  );
};

export const ConfirmationDialog = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <h2 className="dialog-title">{props.title}</h2>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.subtext}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose}>No</Button>
        <Button onClick={props.handelAgree} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const CategoryNames = function ({ categorys_data, bidId }) {
  const categories = Object.values(categorys_data || {});
  const displayCategories = categories.slice(0, 2);
  const remainingCategories = categories
    .slice(2)
    .map((item) => item.name)
    .join(",");
  // Calculate the length of remaining categories
  const remainingCategoriesLength = remainingCategories.split(",").length;
  return (
    <>
      {displayCategories.map((category, index) => (
        <div key={index} style={{ display: "flex", alignItems: "center" }}>
          <div className="categoryCard">
            <CustomText
              ellipsis
              p
              text={Utils.truncateWithEllipsis(category.name, 60)}
              fontSize={"font_size_label"}
              textColor={"text_color_black"}
              textAlign={"text_align_center"}
            />
          </div>
          {/* Add vertical line after each category card except the last one */}
          {(categories.length > 2 || index < displayCategories.length - 1) && (
            <div key={`line-${index}`} className="vertical-line" />
          )}
        </div>
      ))}
      {categories.length > 2 && (
        <div
          className={"category-card-more cursor-pointer"}
          data-tooltip-id={`tooltip-category-name-${bidId}`}>
          <CustomText
            p
            text={`+${remainingCategoriesLength} More...`}
            fontSize={"font_size_label"}
            // textColor={"text_color_black"}
          />
        </div>
      )}
      <ReactTooltip
        style={{ zIndex: 20 }}
        id={`tooltip-category-name-${bidId}`}
        place="bottom"
        content={
          <div>
            <span className="button2">Categories</span>

            <div className="m-t-8 flex-row">
              {categories.slice(2).map((item, index) => (
                <React.Fragment key={index}>
                  {item.name}
                  {index < categories.length - 3 && (
                    <div
                      key={`line-${index}`}
                      className="vertical-line m-l-8"
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        }
      />
    </>
  );
};

export const CampaignListDropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("ALL");

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsOpen(false);
    // You can perform any additional actions based on the selected item here
  };

  return (
    <div className="campaignlist-dropdown">
      <button
        className="campaignlist-dropdown-toggle"
        onClick={() => setIsOpen(!isOpen)}>
        <span className="campaignlist-dropdown-text">Campaign List</span>
        <span
          className={`campaignlist-dropdown-arrow ${
            isOpen ? "rotate-180" : ""
          }`}>
          <Icon iconName={"ProfileDropDownIcon"} width={12} height={8} />
        </span>
      </button>
      {isOpen && (
        <ul className="campaignlist-dropdown-menu">
          {props.dropdownItems.map((item, index) => (
            <li key={index} onClick={() => props.onItemClick(item)}>
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
export const ContractShareListDropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("ALL");

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsOpen(false);
    // You can perform any additional actions based on the selected item here
  };

  return (
    <div className="contractsharelist-dropdown">
      <button
        className="contractsharelist-dropdown-toggle"
        onClick={() => setIsOpen(!isOpen)}>
        <span className="contractsharelist-dropdown-text">Contract List</span>
        <span
          className={`contractsharelist-dropdown-arrow ${
            isOpen ? "rotate-180" : ""
          }`}>
          <Icon iconName={"ProfileDropDownIcon"} width={12} height={8} />
        </span>
      </button>
      {isOpen && (
        <ul className="contractsharelist-dropdown-menu">
          {props.dropdownItems.map((item, index) => (
            <li key={index} onClick={() => props.onItemClick(item)}>
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export const InfluencerListDropdown = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("ALL");

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsOpen(false);
    // You can perform any additional actions based on the selected item here
  };

  return (
    <div className="campaignlist-dropdown">
      <button
        className="campaignlist-dropdown-toggle"
        onClick={() => setIsOpen(!isOpen)}>
        <span className="campaignlist-dropdown-text">
          {props.heading ? props.heading : "Contract List"}
        </span>

        <span
          className={`campaignlist-dropdown-arrow ${
            isOpen ? "rotate-180" : ""
          }`}>
          <Icon iconName={"ProfileDropDownIcon"} width={12} height={8} />
        </span>
      </button>
      {isOpen && (
        <ul className="campaignlist-dropdown-menu">
          {props.dropdownItems.map((item, index) => (
            <li
              key={index}
              onClick={() => {
                props.onItemClick(item.id);
                setIsOpen(!isOpen);
              }}>
              {item.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export const InfluencerListDropdownNew = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("ALL");

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsOpen(false);
    // You can perform any additional actions based on the selected item here
  };

  return (
    <div className="campaignlist-dropdown">
      <button
        className="campaignlist-dropdown-toggle-new"
        onClick={() => setIsOpen(!isOpen)}>
        <span className="campaignlist-dropdown-text-new">Contract List</span>
        <span
          className={`campaignlist-dropdown-arrow ${
            isOpen ? "rotate-180" : ""
          }`}>
          <Icon iconName={"ProfileDropDownIcon"} width={12} height={8} />
        </span>
      </button>
      {isOpen && (
        <ul className="campaignlist-dropdown-menu-new">
          {props.dropdownItems.map((item, index) => (
            <li key={index} onClick={() => props.onItemClick(item)}>
              {item}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

// NEW
// export const InfluencerDropdownNew = (props) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [selectedItem, setSelectedItem] = useState("ALL");

//   const handleItemClick = (item) => {
//     setSelectedItem(item);
//     setIsOpen(false);
//     // You can perform any additional actions based on the selected item here
//   };

//   return (
//     <div className="campaignlist-dropdown-new">
//       <button
//         className="campaignlist-dropdown-toggle"
//         onClick={() => setIsOpen(!isOpen)}>
//         <span className="campaignlist-dropdown-text-new">Contract List</span>
//         <span
//           className={`campaignlist-dropdown-arrow ${
//             isOpen ? "rotate-180" : ""
//           }`}>
//           <Icon iconName={"ProfileDropDownIcon"} width={12} height={8} />
//         </span>
//       </button>
//       {isOpen && (
//         <ul className="campaignlist-dropdown-menu">
//           {props.dropdownItems.map((item, index) => (
//             <li key={index} onClick={() => props.onItemClick(item)}>
//               {item}
//             </li>
//           ))}
//         </ul>
//       )}
//     </div>
//   );
// };

export const MenuItem = function (props) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <li key={props.index} className={"menu-text"}>
      <NavLink
        to={props.navlink}
        onClick={props.onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <Icon
          iconName={
            props.navlink == props.currentPath || isHovered
              ? props.svgIconNameActive
                ? props.svgIconNameActive
                : props.svgIconName
              : props.svgIconName
          }
          isActive={props.navlink == props.currentPath ? true : false}
        />

        <span className="flex-row hover-sidebar-show">
          <CustomText
            p
            text={props.title}
            className="button2  m-l-5"
            // fontSize={"font_size_large"}
            // textAlign={"text_align_left"}
            // fontWeight={"Inter_bold"}
          />
        </span>
        <span style={{ fontSize: 13 }}>
          {props.hasPendingMessages && (
            <div className="red-dot-pending-message-side"></div>
          )}
        </span>
      </NavLink>
    </li>
  );
};

export const CounterProposal = function (props) {
  return (
    <Modal
      isOpen={true}
      onRequestClose={props.onRequestClose}
      style={Styles.CounterStyle}
      contentLabel="Modal">
      <div onClick={props.onRequestClose} className="close-btn-icon">
        <Icon iconName="CloseRoundButtonIcon" />
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          background: "#ffffff",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 40,
          paddingTop: 15,
          marginBottom: 30,
        }}>
        <div className="">
          <CustomText
            h5
            text={props.headingText}
            textColor={"text_grey_400_color"}
            className="m-b-8 typographyBoldH5"
          />
        </div>
      </div>
      <div style={{ paddingTop: 50 }}>
        <div className="m-t-10">
          <div style={{ textAlign: "center" }} className="flex-row">
            <CustomText
              h5
              fontSize={"font_size_large"}
              text={props.text}
              textColor={"text_grey_400_color"}
              fontWeight={"Poppins_regular_large"}
              textAlign={"text_align_left"}
              className="m-b-8 m-l-10"
            />
          </div>
          <CustomTextInput
            className={"input-height-counter"}
            value={props.value}
            onChange={props.onChange}
            placeHolder={props.placeHolder}
            type="number"
          />

          {/* <div className="m-t-20 brand-text-area">
          <CustomTextArea
            invert
            className="custom-text-offer"
            value={""}
            onChange={""}
          />
        </div> */}
        </div>
      </div>
      <div style={{ maxWidth: 350, textAlign: "center" }}>
        <CustomText
          p
          fontSize={"font_size_extraSmall"}
          text={`We will remind influencer about this offer. Once approved, proposal amount will get updated. 1 counter consumes 1 credit`}
          textColor={"text_grey_color"}
          fontWeight={"rubik_regular"}
          noOfLine={"twoLines"}
        />
      </div>
      <div className="m-t-25">
        <PrimaryCta
          fifth
          onClick={props.onCounter}
          fontSize={"font_size_large"}
          className="counter-btn"
          text={"Counter Bid"}
          isLoading={props.isLoading}
          disabled={props.disabled}
        />

        {/* <PrimaryCta
          invert
          onClick={props.onClose}
          className="confirm-btn-bid"
          fontSize={"font_size_large"}
          text={"Cancel"}
          disabled={props.disabled}
        /> */}
        {!isMobile && (
          <button
            style={{ height: 44 }}
            className="confirm-btn-bid  m-l-10"
            onClick={props.onClose}>
            Cancel
          </button>
        )}
      </div>
    </Modal>
  );
};

export const SubscriptionBuyButton = function (props) {
  return (
    <div className="subscription-unlock-button-container">
      {" "}
      <CustomButton
        onClick={props.onClick}
        fontSize={"font_size_large"}
        iconName="lockSubscriptionIcon"
        text={"Unlock"}
        width={200}
        borderRadius={4}
        padding={{ top: 10, right: 40, bottom: 10, left: 40 }}
        textColor={"text_color_light"}
        background={"#090A14"}
        border={"none"}
        boxShadow={"none"}
        hoverColor={"var(--hover-color, #1977F2)"}
      />
    </div>
  );
};
