import React, { useState } from "react";
import CustomTable from "../../components/customTable";
import Header from "../header";
import SideMenu from "../sideMenu";
import { CustomText, PrimaryCta } from "../../components";
import { useNavigate } from "react-router-dom";

const fakeData = [
  {
    script_id: "12345-abcde",
    title: "Sample Title 1",
    text: "This is a sample text for the first record.",
    owner_type: "Admin",
    owner_id: 101,
  },
  {
    script_id: "67890-fghij",
    title: "Sample Title 2",
    text: "This is a sample text for the second record.",
    owner_type: "User",
    owner_id: 102,
  },
  {
    script_id: "54321-zyxwv",
    title: "Sample Title 3",
    text: "This is a sample text for the third record.",
    owner_type: "Guest",
    owner_id: 103,
  },
  {
    script_id: "54321-zyxwv",
    title: "Sample Title 3",
    text: "This is a sample text for the third record.",
    owner_type: "Guest",
    owner_id: 103,
  },
  {
    script_id: "54321-zyxwv",
    title: "Sample Title 3",
    text: "This is a sample text for the third record.",
    owner_type: "Guest",
    owner_id: 103,
  },
  {
    script_id: "54321-zyxwv",
    title: "Sample Title 3",
    text: "This is a sample text for the third record.",
    owner_type: "Guest",
    owner_id: 103,
  },
  {
    script_id: "54321-zyxwv",
    title: "Sample Title 3",
    text: "This is a sample text for the third record.",
    owner_type: "Guest",
    owner_id: 103,
  },
  {
    script_id: "54321-zyxwv",
    title: "Sample Title 3",
    text: "This is a sample text for the third record.",
    owner_type: "Guest",
    owner_id: 103,
  },
  {
    script_id: "54321-zyxwv",
    title: "Sample Title 3",
    text: "This is a sample text for the third record.",
    owner_type: "Guest",
    owner_id: 103,
  },
  {
    script_id: "54321-zyxwv",
    title: "Sample Title 3",
    text: "This is a sample text for the third record.",
    owner_type: "Guest",
    owner_id: 103,
  },
  {
    script_id: "54321-zyxwv",
    title: "Sample Title 3",
    text: "This is a sample text for the third record.",
    owner_type: "Guest",
    owner_id: 103,
  },
  {
    script_id: "54321-zyxwv",
    title: "Sample Title 3",
    text: "This is a sample text for the third record.",
    owner_type: "Guest",
    owner_id: 103,
  },
  {
    script_id: "54321-zyxwv",
    title: "Sample Title 3",
    text: "This is a sample text for the third record.",
    owner_type: "Guest",
    owner_id: 103,
  },
];

const customBidStyles = {
  headRow: {
    style: {
      backgroundColor: "var(--general-secondary-color)", // Change header background color
      color: "var(--gray-gray-300)", // Change header text color
      fontSize: "14px",
      fontWeight: 600,
      lineHight: "19.2px",
      border: "none",
    },
  },
  headCells: {
    style: {
      padding: "10px 0px 10px 20px",
    },
  },
  rows: {
    style: {
      cursor: "pointer",
      fontSize: "12px",
      paddingTop: "10px",
      paddingBottom: "10px",
      fontWeight: 400,
      lineHight: "19.2px",
      "&:hover": {
        backgroundColor: "var(--general-secondary-color)",
        fontWeight: 600,
      },
    },
  },
  cells: {
    style: {
      paddingLeft: "20px",
    },
  },
};
const ScriptScreen = () => {
  const [scriptList, setScriptList] = useState(fakeData);
  console.log("scriptList", scriptList);
  const [start, setStart] = useState(0);

  const ScriptTableSchema = [
    {
      name: "script_id",
      selector: (row) => row.script_id,
    },
    {
      name: "title",
      selector: (row) => row.title,
    },
    {
      name: "text",
      selector: (row) => row.text,
    },
    {
      name: "owner_type",
      selector: (row) => row.owner_type,
    },
    {
      name: "owner_id",
      selector: (row) => row.owner_id,
    },
  ];

  var navigate = useNavigate();
  function createScript() {
    navigate("/create-script");
  }
  return (
    <div className="dashboard-container">
      <SideMenu />
      <div className="main-screen">
        <Header />
        <div style={{ minHeight: 500 }}>
          <div className="page-container-campaignList">
            <div className="bid-list-dropdown-header-container m-l-20 m-r-20">
              <div>
                {scriptList && (
                  <div
                    style={{ marginTop: 12 }}
                    className="flex-row justify-between align-center">
                    <CustomText
                      p
                      text={"Top "}
                      fontSize={"font_size_regular"}
                      textColor={"text_color_primary"}
                      fontWeight={"Poppins_regular_medium"}
                    />
                    &nbsp;
                    <CustomText
                      p
                      text={`${start + 1}-${scriptList && scriptList?.length} 
                   Results`}
                      fontSize={"font_size_regular"}
                      textColor={"text_color_primary"}
                      fontWeight={"Poppins_regular"}
                    />
                  </div>
                )}
              </div>
              <div className="create-campaign-button">
                <PrimaryCta
                  third
                  fontSize={"font_size_regular"}
                  className="create-campaign"
                  text={"Create Script"}
                  onClick={() => createScript()}
                />
              </div>
            </div>
            <div className="custom-table">
              <CustomTable
                className="CustomTable"
                tableSchema={ScriptTableSchema}
                paginationPerPage={10}
                tableData={scriptList}
                rowPaddingTop={16}
                rowPaddingBottom={16}
                customStyles={customBidStyles}
                paginationTotalRows={scriptList?.length}
                pagination
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ScriptScreen;
