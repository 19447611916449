import React, { useEffect, useState } from "react";
import {
  CustomText,
  Image,
  CustomTextInputNew,
  PrimaryCta,
  LinkCta,
  UnderLinedLinkCta,
  CustomRadioButton,
} from "../../components";
import Core from "../../common/clientSdk";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import * as Actions from "../../redux/actions";
import Select from "react-dropdown-select";
import { useLocation, useNavigate } from "react-router-dom";
import { BRAND_ID_KEY } from "../../common/web_storage_keys";
import WebStorage from "../../common/web_storage";
import * as CampaignData from "../../common/data/campaign";
import PhoneInput,{isValidPhoneNumber} from "react-phone-number-input";
const NewSignUp = () => {
  const [step, setStep] = useState(1);
  const [category, setCategory] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [showAgency, setShowAgency] = useState("AGENCY");
  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    agencyName: "",
    brandName: "",
    confirmPassword: "",
  });
  console.log("formData", formData);

  var navigate = useNavigate();

  var redux_dispatch = useDispatch();

  console.log("phoneNumber", phoneNumber);
  const location = useLocation();
  const { data } = location.state || "";

  const updateProgress = (status) => {
    setLoading(status);
  };

  useEffect(() => {
    if (data) {
      setFormData({ ...formData, email: data });
    }
    validateFieldsForStep2();
  }, [data, formData]);

  console.log("data", data);
  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Valid email format

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    console.log("Name", name, value);
  };

  const handleRoleChange = (e) => {
    const role = e.target.value;
    setShowAgency(role);

    // Clear data for the other role
    if (role === "AGENCY") {
      setFormData({ ...formData, brandName: "" });
      setCategory("");
    } else {
      setFormData({ ...formData, agencyName: "" });
    }
  };

  const validateFieldsForStep2 = () => {
    if (showAgency === "AGENCY" && formData.agencyName.trim() !== "") {
      setIsButtonDisabled(false);
    } else if (showAgency === "BRAND" && formData.brandName.trim() !== "") {
      setIsButtonDisabled(false);
    } else {
      setIsButtonDisabled(true);
    }
  };
  const validations = () => {
    const newErrors = {};

    // Full Name validation
    if (
      !formData.name ||
      formData.name.length < 3 ||
      formData.name.length > 30
    ) {
      newErrors.name = "Full name must be between 3 and 30 characters.";
    }

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      newErrors.email = "Enter a valid email address.";
    }

    // Phone validation
    const phoneRegex = /^\+?[0-9]{1,3}[0-9]{10}$/;
    if (!phoneRegex.test(phoneNumber) ) {
      newErrors.phone = "Enter a valid 10 digit phone number.";
    }

    // Password validation
    const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d).{6,}$/;
    if (
      !passwordRegex.test(formData.password) ||
      formData.password.startsWith("#")
    ) {
      newErrors.password =
        "Password must contain letters, numbers, and no special char at start.";
    }

    // Confirm Password validation
    if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match.";
    }

    setErrors(newErrors);

    // If no errors, return true
    return Object.keys(newErrors).length === 0;
  };
  const validateName = (value) => {
    if (value.length < 3 || value.length > 30) {
      setErrors({
        ...errors,
        name: "Name must be between 3 and 30 characters.",
      });
      return false;
    }
    setErrors({ ...errors, name: "" });
    return true;
  };
  const validateEmail = (value) => {
    if (value.length == 0) {
      setErrors({ ...errors, email: "Please enter email address." });
      return false;
    } else if (!emailRegex.test(value)) {
      setErrors({ ...errors, email: "Please enter a valid email address." });
      return false;
    }
    setErrors({ ...errors, email: "" });
    return true;
  };
  const validatePhone = () => {
    let validPhoneNumber=isValidPhoneNumber(phoneNumber)
    console.log("validPhoneNumber",validPhoneNumber)
    if (!validPhoneNumber) {
      console.log("phoneNumber", phoneNumber);
      setErrors({ ...errors, phone: "Enter a valid 10 digit phone number" });
      return false;
    }
    setErrors({ ...errors, phone: "" });
    return true;
  };

  const validatePassword = (value) => {
    const passwordRegex = /^(?![\W_])[A-Za-z0-9]*[\W_][A-Za-z0-9]*$/;

    if (value.length < 8) {
      setErrors({
        ...errors,
        password: "Password must be at least 8 characters long.",
      });
      return false;
    }
    if (!passwordRegex.test(value)) {
      setErrors({
        ...errors,
        password:
          "Password must include at least one special character, not at the start.",
      });
      return false;
    }
    if (!/[A-Za-z]/.test(value) || !/[0-9]/.test(value)) {
      setErrors({
        ...errors,
        password: "Password must include at least one letter and one digit.",
      });
      return false;
    }
    setErrors({ ...errors, password: "" });
    return true;
  };
  const checkPasswordValidation = (value) => {
    if (value != formData.password) {
      setErrors({ ...errors, confirmPassword: "Passwords do not match." });
    } else {
      setErrors({ ...errors, confirmPassword: "" });
    }
  };

  const removePlusSign = (value) => {
    return value.replace(/^\+/, ''); // Removes the '+' from the beginning if present
  };

  function brandSignUp() {
    var params = {
      org_data: JSON.stringify({
        name: formData.agencyName || formData.brandName,
        organization_type: showAgency,
        category: category[0]?.value ? category[0]?.value : null,
      }),
      user_data: JSON.stringify({
        name: formData.name,
        phone_country: "in",
        phone_number: removePlusSign(phoneNumber),
        email: formData.email,
        password: formData.password,
      }),
      address_data: JSON.stringify({}),
    };
    Core.brandSignup(signUpSuccess, signUpFailed, updateProgress, params);
  }

  function signUpSuccess(response_data, response2) {
    console.log("response,response2", response_data, response2);
    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_DATA,
      payload: { user_data: response_data.user_data },
    });

    redux_dispatch({
      type: Actions.UPDATE_ORG_DATA,
      payload: { org_deep_data: response_data.org_data },
    });

    redux_dispatch({
      type: Actions.UPDATE_BRAND_USER_AUTH,
      payload: { auth_token: response_data.auth },
    });
    redux_dispatch({
      type: Actions.UPDATE_BRAND_DATA,
      payload: { brand_deep_data: response_data.brand_data },
    });

    WebStorage.saveDataInWebStorage(
      BRAND_ID_KEY,
      response_data.brand_data.brand_data.brand_id
    );

    navigate("/");
    toast.success("SignUp Successful");
  }
  function signUpFailed() {
    toast.error("SignUp Failed");
  }

  console.log("category", category);

  const handleNext = () => {
    if (validations()) {
      setStep(step + 1);
    } else {
      toast.error("Pls Fill the form Carefully");
    }
  };

  const handleBack = () => {
    setStep(step - 1);
  };

  console.log("showAgency", showAgency);

  function updateCategory(selectedList) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.value);
    });

    setCategory(selectedList);

    value.flatMap((key) => CampaignData.FULL_CATEGORIES.subcategories[key]);
  }
  console.log(
    "CampaignData.FULL_CATEGORIES",
    Object.values(CampaignData.FULL_CATEGORIES.CATEGORYS)
  );
  return (
    <div style={styles.parentContainer}>
      {/* Left Container */}
      <div className="new-sign-up-left-side" style={styles.leftContainer}>
        {/* <img
          src="../assets/images/new-signup-img.png" // Replace with your image URL
          alt="Placeholder"
          style={styles.image}
        /> */}
      </div>

      {/* Right Container */}
      <div style={styles.rightContainer}>
        <div style={styles.content}>
          <div className="new-sign-up-right-side">
            <div className="signup-right-side-top-container">
              <div className="signup-right-side-top-container-div-1 ">
                <div>
                  <Image source={"../assets/images/Reelax.png"} />
                </div>
                <div className="signup-right-side-top-container-div-2 ">
                  SignUp to Reelax
                </div>
              </div>
            </div>
            <div className="right-panel">
              <div className="form-container">
                {step === 1 && (
                  <div className="signup-detail">
                    <div className="progess-steps">Step 1 of 2</div>
                    <div className="progress-bar">
                      <div
                        className="progress"
                        style={{ width: `${(step / 2) * 100}%` }}></div>
                    </div>
                    <div className="signup-right-side-top-container-div-3 ">
                      <CustomText
                        h5
                        fontSize={"font_size_large"}
                        text={`Full Name`}
                      />
                    </div>

                    <div className="signup-right-side-top-container-div-4 ">
                      <CustomTextInputNew
                        newWidth={"login-right-side-top-input-field"}
                        placeholder="Full Name"
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        onBlur={(e) => validateName(e.target.value)}
                      />
                      {errors.name && (
                        <p style={{ color: "#F04248" }}>{errors.name}</p>
                      )}
                    </div>
                    <div className="signup-right-side-top-container-div-3 ">
                      <CustomText
                        h5
                        fontSize={"font_size_large"}
                        text={`Email`}
                      />
                    </div>

                    <div className="signup-right-side-top-container-div-4 ">
                      <CustomTextInputNew
                        newWidth={"login-right-side-top-input-field"}
                        placeholder="Enter email address"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        onBlur={(e) => validateEmail(e.target.value)}
                      />
                      {errors.email && (
                        <p style={{ color: "#F04248" }}>{errors.email}</p>
                      )}
                    </div>
                    <div className="signup-right-side-top-container-div-3 ">
                      <CustomText
                        h5
                        fontSize={"font_size_large"}
                        text={`Phone Number`}
                      />
                    </div>

                    <div className="">
                      <PhoneInput
                        className="signup-right-phoneNumber"
                        defaultCountry="IN"
                        value={phoneNumber}
                        onChange={setPhoneNumber}
                        onBlur={validatePhone}
                        placeholder="Enter Phone Number"
                      />
                      {errors.phone && (
                        <p style={{ color: "#F04248" }}>{errors.phone}</p>
                      )}
                    </div>
                    <div className="signup-right-side-top-container-div-3 ">
                      <CustomText
                        h5
                        fontSize={"font_size_large"}
                        text={`Password`}
                      />
                    </div>

                    <div className="signup-password-field ">
                      <CustomTextInputNew
                        newWidth={"login-right-side-top-input-field"}
                        placeholder="Password"
                        name="password"
                        type="password"
                        required
                        value={formData.password}
                        onChange={handleChange}
                        onBlur={(e) => validatePassword(e.target.value)}
                      />
                      {errors.password && (
                        <p style={{ color: "#F04248" }}>{errors.password}</p>
                      )}
                    </div>

                    <div className="signup-right-side-top-container-div-3 ">
                      <CustomText
                        h5
                        fontSize={"font_size_large"}
                        text={`Confirm Password`}
                      />
                    </div>

                    <div className="signup-show-password-field">
                      <CustomTextInputNew
                        newWidth={"login-right-side-top-input-field"}
                        name="confirmPassword"
                        type="password"
                        required
                        placeholder="Confirm Password"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        onBlur={(e) => checkPasswordValidation(e.target.value)}
                      />
                      {errors.confirmPassword && (
                        <p style={{ color: "#F04248" }}>
                          {errors.confirmPassword}
                        </p>
                      )}
                    </div>

                    <div className="signin-right-side-top-container-div-5">
                      <PrimaryCta
                        className="login-right-side-input-field"
                        fullWidth100
                        fontSize={"font_size_large"}
                        text={"Continue"}
                        onClick={handleNext}
                      />
                    </div>
                  </div>
                )}
                {step === 2 && (
                  <div>
                    <div className="progess-steps">Step 2 of 2</div>
                    <div className="progress-bar">
                      <div
                        className="progress"
                        style={{ width: `${(step / 2) * 100}%` }}></div>
                    </div>
                    <div>
                      <div className="signup-right-side-top-container-div-3 ">
                        <div style={{ marginBottom: "12px" }}>
                          <CustomText
                            h5
                            fontSize={"font_size_large"}
                            text={`Role`}
                          />
                        </div>
                        <div className="role-radio-button-container">
                          <div className="role-radio-button-inner-container">
                            <CustomRadioButton
                              name="role"
                              value="AGENCY"
                              checked={showAgency == "AGENCY"}
                              label={"Agency"}
                              onChange={handleRoleChange}
                            />
                          </div>
                          <div className="role-radio-button-inner-container">
                            <CustomRadioButton
                              name="role"
                              value="BRAND"
                              checked={showAgency == "BRAND"}
                              label={"Brand"}
                              onChange={handleRoleChange}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {showAgency === "AGENCY" && (
                      <div>
                        <div className="signup-right-side-top-container-div-3 ">
                          <CustomText
                            h5
                            fontSize={"font_size_large"}
                            text={`Name`}
                          />
                        </div>

                        <div style={{ marginBottom: "24px" }}>
                          <CustomTextInputNew
                            newWidth={"login-right-side-top-input-field"}
                            placeholder="Enter Agency Name"
                            name="agencyName"
                            value={formData.agencyName}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    )}
                    {showAgency === "BRAND" && (
                      <div>
                        <div className="signup-right-side-top-container-div-3 ">
                          <CustomText
                            h5
                            fontSize={"font_size_large"}
                            text={`Name`}
                          />
                        </div>

                        <div className="signup-right-side-top-container-div-4 ">
                          <CustomTextInputNew
                            newWidth={"login-right-side-top-input-field"}
                            placeholder="Enter Brand Name"
                            name="brandName"
                            value={formData.brandName}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="signup-right-side-top-container-div-3 ">
                          <CustomText
                            h5
                            fontSize={"font_size_large"}
                            text={`Category`}
                          />
                        </div>
                        <div style={{ marginBottom: "24px" }}>
                          <Select
                            multi={false}
                            options={Object.values(
                              CampaignData.FULL_CATEGORIES.CATEGORYS
                            )}
                            // values={category}
                            onChange={(value) => updateCategory(value)}
                            placeholder="Select Category"
                            labelField="label"
                            valueField="value"
                            autoFocus={false}
                            color="#c8c2c2"
                            // Customcolor
                            style={{
                              border: "1px solid #b3b3b6",
                              backgroundColor: "#fff",
                              borderRadius: 8,
                              paddingBottom: "10px",
                              height: "48px",
                              paddingTop: "10px",
                              marginBottom: "5px",
                              option: {
                                backgroundColor: "red",
                                onhover: "grey",
                              },
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <div>
                      <PrimaryCta
                        fifth
                        className="login-right-side-input-field"
                        fullWidth100
                        fontSize={"font_size_large"}
                        text={"SignUp"}
                        isLoading={loading}
                        onClick={brandSignUp}
                        disabled={isButtonDisabled}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        zIndex={9999999}
      />
    </div>
  );
};
const styles = {
  parentContainer: {
    display: "flex",
    flex: "1",
    height: "100vh", // Full viewport height
    overflow: "hidden",
  },
  leftContainer: {
    flex: "1",
    position: "sticky", // Keeps the left container fixed
    top: 0,
    left: 0,
    borderRadius: "20px",
  },
  rightContainer: {
    flex: "1", // Take the remaining space
    overflowY: "auto", // Enable vertical scrolling
    // padding: "34px 80px 34px 50px",
    backgroundColor: "#fff",
  },
  content: {
    // minHeight: "200%",
    // Dummy height for scrolling effect
  },
};
export default NewSignUp;
