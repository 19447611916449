import { useEffect, useState } from "react";
import Modal from "react-modal";
import { CustomText, CustomTextInput, PrimaryCta } from "../components";
import Icon from "../components/core_component/icon";
import Core from "../common/clientSdk";
import { toast } from "react-toastify";
import campaign from "../common/data/campaign";
import Config from "../common/data/config";
export const RepostJobOptionModal = (props) => {
  const [loading, setLoading] = useState(false);
  const campaignType = props?.campaignDetail?.campaign_data?.type;
  useEffect(() => {}, []);

  function repostCampaign() {
    var params = {
      campaign_id: props.campaignId,
    };

    Core.extendDataCampaign(
      repostCampaignSuccess,
      repostCampaignFailed,
      (status) => setLoading(status),
      params
    );
  }

  function repostCampaignSuccess(response) {
    props.handleUpdateCampaign();
    props.closeModalRepost();
  }

  function repostCampaignFailed(error) {
    props.closeModalRepost();
    toast.error(error.map((item) => item.m).join(","));
  }

  function getCreditDeductionString() {
    if (campaignType == "social_media") {
      return Config.EXTEND_SOCIAL_MEDIA_TYPE_CONTENT_CREDIT_DEDUCTION;
    } else {
      return Config.EXTEND_UGC_TYPE_CONTENT_CREDIT_DEDUCTION;
    }
  }

  return (
    <Modal
      isOpen={true}
      onRequestClose={props.closeModalRepost}
      style={CancelReminder}>
      {/* <div className="modal-heading-text p-t-25">
        <div className="collection-link-copy-header">
          <CustomText
            h5
            text={"Are You Sure You Want to Repost This Campaign?"}
            textColor={"text_grey_400_color"}
            className="m-b-20 typography-centerH7"
          />
          <div onClick={props.closeModalRepost} className="close-btn-icon">
            <Icon iconName="CloseRoundButtonIcon" />
          </div>
        </div>
      </div>
      <div className="share-collection-popout" style={{ paddingTop: 40 }}>
        <div className="">
          <CustomText
            p
            text={`Do you want to Add Influencer to `}
            textColor={"text_color_primary"}
            fontSize={"font_size_xl"}
            className="typographyH7 m-b-8"
          />
        </div>
        <div
          style={{
            marginTop: "20px",
            display: "flex ",
            justifyContent: "center",
            gap: "30px",
          }}
        >
          <PrimaryCta
            invert
            className="back-btn-campaign"
            onClick={() => props.closeModalRepost()}
            fontSize={"font_size_large"}
            text={"No"}
          />
          <PrimaryCta
            third
            fontSize={"font_size_regular"}
            className="header-credit-right"
            text={"Yes"}
            isLoading={loading}
            onClick={() => repostCampaign()}
          />
        </div>
      </div> */}

      <div onClick={props.closeModalRepost} className="close-btn-icon">
        <Icon iconName="CloseRoundButtonIcon" />
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          background: "#ffffff",

          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: 40,
          paddingTop: 5,
          paddingBottom: 10,
          marginBottom: 30,
        }}>
        <div className="">
          <CustomText
            h4
            text={"Renew Campaign"}
            textColor={"text_grey_400_color"}
            className="m-b-8 typographyBoldH5"
          />
        </div>
      </div>
      <div style={{ paddingTop: 50 }}>
        <div className="m-t-10">
          <div style={{ textAlign: "center" }} className="flex-row">
            <CustomText
              h5
              fontSize={"font_size_large"}
              text={props.text}
              textColor={"text_grey_400_color"}
              fontWeight={"Poppins_regular_large"}
              textAlign={"text_align_left"}
              className="m-b-8 m-l-10"
            />
          </div>

          {/* <div className="m-t-20 brand-text-area">
          <CustomTextArea
            invert
            className="custom-text-offer"
            value={""}
            onChange={""}
          />
        </div> */}
        </div>
      </div>
      <div style={{ maxWidth: 350, textAlign: "center" }}>
        <CustomText
          p
          fontSize={"font_size_extraSmall"}
          text={`This campaign will be renewed for 1 month and you will be charged ${getCreditDeductionString()} to renew this campaign`}
          textColor={"text_grey_color"}
          fontWeight={"rubik_regular"}
          noOfLine={"twoLines"}
        />
      </div>
      <div className="m-t-25">
        <PrimaryCta
          fifth
          onClick={() => repostCampaign()}
          fontSize={"font_size_large"}
          className="counter-btn"
          text={"Yes"}
          isLoading={loading}
          disabled={props.disabled}
        />

        {/* <PrimaryCta
          invert
          onClick={props.onClose}
          className="confirm-btn-bid"
          fontSize={"font_size_large"}
          text={"Cancel"}
          disabled={props.disabled}
        /> */}
        <PrimaryCta
          invert
          onClick={props.closeModalRepost}
          fontSize={"font_size_large"}
          className="counter-btn"
          text={"No"}
          isLoading={loading}
          disabled={props.disabled}
        />
      </div>
    </Modal>
  );
};

const CancelReminder = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: 20,
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    borderColor: "var( --blue-blue-500)",
    textAlign: "center",
    zIndex: "5",
    minHeight: "40%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    minWidth: 352,
    justifyContent: "space-between",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 10,
  },
};
