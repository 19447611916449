import React from "react";
import NewSignInRightSide from "./newSignInRightSide";
import NewLoginLeftSide from "./newLoginLeftSide";
const NewSignIn=()=>{
    return(
        <div className="new-login-screen">
        <NewLoginLeftSide/>
        <NewSignInRightSide/>
        </div>
    )
}
export default NewSignIn;