import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Core from "../../common/clientSdk";
import { CustomText, PrimaryCta } from "../../components";
import { toast } from "react-toastify";

function CreateCampaignCollection(props) {
  const [isLoading, setLoading] = useState(false);

  function updateProgress(status) {
    setLoading(status);
  }

  function postAddInfluencerCollection() {
    const params = {
      collection_id: props.selectedCollectionId,
      influencer_id: props.influencer_id,
    };
    Core.postAddInfluencerCollection(
      postAddInfluencerCollectionSuccess,
      postAddInfluencerCollectionFailed,
      updateProgress,
      params
    );
  }

  function postAddInfluencerCollectionSuccess(response_data) {
    toast.success("Influencer Added List Successfully");
    props.getCollectionInfluencerList();
    props.closeModal();
  }

  function postAddInfluencerCollectionFailed(errorList) {
    props.closeModal();

    toast.error(errorList.map((item) => item.m).join(", "));
  }

  return (
    <div>
      <Modal
        isOpen={true}
        onRequestClose={props.closeModal}
        style={CancelReminder}
      >
        <div className="">
          <CustomText
            p
            text={`Do you want to add influencer in list `}
            textColor={"text_color_primary"}
            fontSize={"font_size_xl"}
            className="typographyH7 m-b-8"
          />
        </div>
        <div
          style={{
            marginTop: "20px",
            display: "flex ",
            justifyContent: "center",
            gap: "30px",
          }}
        >
          <PrimaryCta
            invert
            className="back-btn-campaign"
            onClick={() => props.closeModal()}
            fontSize={"font_size_large"}
            text={"No"}
          />
          <PrimaryCta
            third
            fontSize={"font_size_regular"}
            className="header-credit-right"
            text={"Yes"}
            isLoading={isLoading}
            onClick={() => postAddInfluencerCollection()}
          />
        </div>
      </Modal>
    </div>
  );
}

export default CreateCampaignCollection;
// const customStyle={{
//     overlay: {
//       backgroundColor: "rgba(0, 0, 0, 0.5)",
//       zIndex: 999999,
//     },
//     content: {
//       top: "50%",
//       left: "50%",
//       right: "auto",
//       bottom: "auto",
//       // borderColor: "#f55879",
//       backgroundColor: "#F0F3F9",

//       marginRight: "-50%",
//       transform: "translate(-50%, -50%)",
//       padding: "20px",
//       borderRadius: "8px",
//       boxShadow: "0 2px 10px rgba(0, 0, 0, 0.15)",
//     },
//   }}

const CancelReminder = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    textAlign: "center",
    zIndex: "5",
    height: "40%",
    display: "flex",
    flexDirection: "column",
    minWidth: "460px",
    height: "130px",
    alignItems: "center",
    justifyContent: "center",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.95)",
    zIndex: 10,
  },
};
