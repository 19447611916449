import React from "react";
import { useState } from "react";
import Header from "../header";
import SideMenu from "../sideMenu";

const MarketPlace=()=>{
  const items = [
        { id: 1, name: "SWIGGY" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 2, name: "KFC" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "ZOMATO" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 4, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 5, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 6, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 7, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 8, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 9, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 10, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 11, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 12, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 13, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 4, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."},
        { id: 3, name: "Item 3" ,details:"The battle of Cane Hill was fought during the American Civil War on November 28, 1862, near the town of Cane Hill, Arkansas. Union troops under James G. Blunt had pursued Confederate troops commanded by Thomas C. Hindman into northwestern Arkansas, and Hindman saw an opportunity to attack Blunt while the latter was isolated. Confederate cavalry under John S. Marmaduke moved to Cane Hill to collect supplies. Blunt moved to attack Marmaduke on November 27."}
      ];
  const [expandedIndex, setExpandedIndex] = useState(null);
  const cardsPerPage = 10; // Number of cards per page
  const [currentPage, setCurrentPage] = useState(1);

  // Navigate to the first page
  const goToFirstPage = () => {
    setCurrentPage(1);
  };

  // Navigate to the last page
  const goToLastPage = () => {
    setCurrentPage(Math.ceil(items.length / cardsPerPage));
  };

  // Navigate to the previous page
  const goToPreviousPage = () => {
    setCurrentPage(prev => Math.max(prev - 1, 1));
  };

  // Navigate to the next page
  const goToNextPage = () => {
    setCurrentPage(prev => Math.min(prev + 1, Math.ceil(items.length / cardsPerPage)));
  };

  // Calculate the cards to display based on the current page
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = items.slice(indexOfFirstCard, indexOfLastCard);

  // Calculate the total number of pages
  const totalPages = Math.ceil(items.length / cardsPerPage);
  const toggleExpand = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
      };
    return(
              <div className="">
                <SideMenu />
                <div >
                  <Header />
                  <div className="market-place">
                      {currentCards.map((item,index) => (
                          <div key={index} className="market-place-containers">
                            <div style={{display:"flex",alignItems:"center",paddingTop:5}}>
                                <div className="">
                                <img className="market-place-img" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS8Yo7JELI2ZCnmOwOXnrBnF9_AVL3ArXZfKw&s"  width="40px" height="40px" />
                                </div>
                                <div style={{display:"flex",flexDirection:"column",gap:1}}>
                                <div>
                                {item.name}
                                </div>
                                <div>
                                {item.id}
                                </div>
                                </div>
                            </div>
                            <div>{item.name}</div>
                            <div><hr/></div>
                            <div
                             style={{
                                display: "-webkit-box",
                                WebkitLineClamp: expandedIndex === index ? "unset" : 4,
                                WebkitBoxOrient: "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                textAlign:"left",
                                color:"grey"
                              }}>
                                {item.details}
                            </div>
                            <div style={{alignContent:"left"}}>
                                <button
                                 onClick={() => toggleExpand(index)}
                                 style={{
                                 marginTop: "10px",
                                 background: "none",
                                 border: "none",
                                 color: "grey",
                                 cursor: "pointer",
                                 textDecoration: "underline"
                                       }}
                                  >
                                {expandedIndex === index ? "See Less" : "See More"}
                           </button> 
                           </div>
                            <div><hr/></div>
                            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                             <div>
                                {item.name}
                             </div>
                             <div style={{display:"flex",justifyContent:"space-between"}}>
                               <div style={{marginRight:10}}>
                                <button className="call-directly">
                                     📞 Call Directly
                                </button>
                                </div>
                               <div>
                                <button className="quick-chat"
                                >
                                     📑 Quick Chat
                                </button>
                                </div>
                             </div>
                            </div>
                          </div>
                       ))}
                  </div>
                  <div className="pagination">
        <button onClick={goToFirstPage} disabled={currentPage === 1}>First</button>
        <button onClick={goToPreviousPage} disabled={currentPage === 1}>Previous</button>
        
        <span>Page {currentPage} of {totalPages}</span>
        
        <button onClick={goToNextPage} disabled={currentPage === totalPages}>Next</button>
        <button onClick={goToLastPage} disabled={currentPage === totalPages}>Last</button>
      </div>
                </div>
              </div>
            );
          };
export default MarketPlace;