import React, { useEffect, useState } from "react";
import { Stepper, Step } from "react-form-stepper";
import { MdDescription } from "react-icons/md";
import StepWizard from "react-step-wizard";
import CampaignType from "./createCampaignType";
import SocialMediaPlatForm from "./createSocialMediaPlatForm";
import TargetInfluencer from "./createTargetInfluencers";
import CampaignDetails from "./createCampaignDetails";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Core from "../../common/clientSdk";
import { useReducer } from "react";
import { Loader } from "../loader";
import { useSnackbar } from "react-simple-snackbar";
import { Crisp } from "crisp-sdk-web";

//   const handleBack = () => {
//     props.previousStep();
//   };

//   const handleNext = () => {
//     props.nextStep();
//   };

//   const handleFinish = () => {
//     props.lastStep();
//   };

//   return (
//     <div>
//       <Row>
//         {props.currentStep > 1 && (
//           <Col>
//             <Button onClick={handleBack}>Back</Button>
//           </Col>
//         )}
//         <Col>
//           {props.currentStep < props.totalSteps && (
//             <Button onClick={handleNext}>Next</Button>
//           )}
//           {props.currentStep === props.totalSteps && (
//             <Button onClick={handleFinish}>Finish</Button>
//           )}
//         </Col>
//       </Row>
//     </div>
//   );
// };

const Wizard = (props) => {
  const [stepWizard, setStepWizard] = useState(null);
  const [user, setUser] = useState({});
  const [activeStep, setActiveStep] = useState(0);
  const [isEdit, setIsEdit] = useState(true);
  const [campaignDetail, setCampaignDetail] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [openSnackbar, closeSnackbar] = useSnackbar();

  const campaignId = useParams().id;

  useEffect(() => {
    if (campaignId) {
      getCampaignUserDetail(updateProgress);
    }
    // Crisp.chat.hide();
  }, [campaignId]);

  function updateProgress(status) {
    setLoading(status);
  }

  var redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });

  const { BrandUser = {} } = redux_selector;
  const { brand_deep_data = {} } = BrandUser;
  const { brand_data = {} } = brand_deep_data;
  const { org_id = {} } = brand_data;
  const { brand_id = {} } = brand_data;

  function getCampaignUserDetail(progress_cb) {
    const params = {
      brand_id: brand_data.brand_id,
      campaign_id: campaignId,
    };
    Core.getCampaignUserDetail(
      getCampaignUserDetailSuccess,
      getCampaignUserDetailFailed,
      progress_cb,
      params
    );
  }

  function getCampaignUserDetailSuccess(response_data) {
    if (campaignId) {
      setCampaignDetail(response_data.campaign_data);
      setLoading(false);
    }
  }

  function getCampaignUserDetailFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  const assignStepWizard = (instance) => {
    setStepWizard(instance);
  };

  const assignUser = (val) => {
    setUser((user) => ({
      ...user,
      ...val,
    }));
  };

  const handleStepChange = (e) => {
    setActiveStep(e.activeStep - 1);
  };

  const handleComplete = () => {
    alert("You r done. TQ");
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="create-campaign-wizard-container">
          <Stepper
            style={{ width: "-webkit-fill-available" }}
            activeBgColor="#000"
            activeTextColor="#FFF"
            styleConfig={{
              activeBgColor: "#090a14",
              activeTextColor: "#fff",
              completedTextColor: "#fff",
              completedBgColor: "#090a14",
              inactiveBgColor: "",
              inactiveTextColor: "#090a14",
            }}
            activeStep={activeStep}
          >
            <Step
              className="label-wizard"
              label="Configuration"
              style={{
                border: "1px solid var(--gray-gray-500)",
                color: "#090a14",
              }}
            />
            <Step
              label="Platform"
              style={{
                color: "#090a14",
                border: "1px solid var(--gray-gray-500)",
              }}
            />
            <Step
              label="Demographics"
              style={{
                color: "#090a14",
                border: "1px solid var(--gray-gray-500)",
              }}
            />
            <Step
              label="Details"
              style={{
                color: "#090a14",
                border: "1px solid var(--gray-gray-500)",
              }}
            />
          </Stepper>

          <StepWizard
            instance={assignStepWizard}
            onStepChange={handleStepChange}
          >
            <CampaignType
              userCallback={assignUser}
              isEdit={isEdit}
              campaignDetail={campaignDetail.campaign_data}
              campaignDeepData={props.campaignDeepData}
              setCampaignDeepData={props.setCampaignDeepData}
            />
            <SocialMediaPlatForm
              user={user}
              userCallback={assignUser}
              isEdit={isEdit}
              campaignDetail={campaignDetail}
              campaignDeepData={props.campaignDeepData}
              setCampaignDeepData={props.setCampaignDeepData}
            />
            <TargetInfluencer
              user={user}
              userCallback={assignUser}
              isEdit={isEdit}
              campaignDetail={campaignDetail}
              campaignDeepData={props.campaignDeepData}
              setCampaignDeepData={props.setCampaignDeepData}
            />
            <CampaignDetails
              user={user}
              completeCallback={handleComplete}
              isEdit={isEdit}
              org_id={org_id}
              brand_id={brand_id}
              auth_token={redux_selector.Auth.auth_token}
              campaignDetail={campaignDetail}
              campaignDeepData={props.campaignDeepData}
              setCampaignDeepData={props.setCampaignDeepData}
            />
          </StepWizard>
        </div>
      )}
    </>
  );
};

export default Wizard;
