import React from "react";
import { CustomTextInput, PrimaryCta, CustomText } from "../../components";
import SideMenu from "../sideMenu";
import Header from "../header";
import { useState } from "react";
import Core from "../../common/clientSdk";
import validator from "validator";
import { Navigate, useNavigate } from "react-router-dom";
// import "react-toastify/dist/ReactToastify.css";
import { useSnackbar } from "react-simple-snackbar";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-dropdown-select";
import countryList from "../../common/data/country";

import PhoneInput from "react-phone-number-input";

function InviteMember() {
  const [loading, set_loading] = useState(false);
  const [memberName, setMemberName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [country, setCountry] = useState(["IN"]);
  const [countrySelected, setCountrySelected] = useState([
    {
      name: "India",
      calling_code: "+91",
      country_code: "IN",
      currency: {
        code: "INR",
        name: "Indian Rupee",
        symbol: "₹",
      },
    },
  ]);
  const [openSnackbar] = useSnackbar();

  const [error, set_error] = useState({
    member_name: "",
    w_number: "",
    email: "",
  });

  var navigate = useNavigate();

  function updateProgress(status) {
    set_loading(status);
  }
  function validateName(value) {
    if (validator.isEmpty(value, {})) {
      set_error({ ...error, member_name: "Please enter name" });
    } else {
      set_error({ ...error, member_name: "" });
      return true;
    }
  }
  function validateCountry(value) {
    if (value) {
    }
  }
  function validateEmail(value) {
    if (!validator.isEmail(value, {})) {
      set_error({ ...error, email: "Please enter valid email" });
    } else {
      set_error({ ...error, email: "" });
      return true;
    }
  }
  function validateNumber() {
    if (!number) {
      toast.error("Please Enter Whatsapp Number");
      return false;
    }

    return true;
  }
  function postInviteMembers(progress_cb) {
    var params = {
      name: memberName,
      email: email,
      // country: country.toString(),
      number: removePlusSign(number),
    };

    if (validateName(memberName) && validateEmail(email) && validateNumber()) {
      Core.postInviteMembers(
        postInviteMembersSuccess,
        postInviteMembersFailed,
        progress_cb,
        params
      );
    }
  }

  console.log("number", number);

  function postInviteMembersSuccess(response_data) {
    toast.success("Successfully  send");
    navigate("/team-members-list");
  }

  function postInviteMembersFailed(errorList) {
    toast.error(errorList);
  }

  function removePlusSign(phoneNumber) {
    // Check if the phone number starts with a '+'
    if (number) {
      if (phoneNumber.startsWith("+")) {
        // Return the substring starting from index 1 (excluding the '+')
        return phoneNumber.substring(1);
      }
    }

    // If no '+' is found, return the original phone number
    return phoneNumber;
  }

  function updateCounty(selectedItem) {
    console.log("selectedItem", selectedItem);
    setCountrySelected(selectedItem);

    setCountry(
      selectedItem ? selectedItem.map((option) => option.country_code) : []
    );
  }
  console.log("number", number);

  return (
    <div className="dashboard-container">
      <SideMenu />

      <div className="main-screen">
        <Header />
        <div className="page-container">
          <div className="campaign-text-heading">
            <CustomText
              p
              text={"Invite Member"}
              textColor={"text_color_primary"}
              fontSize={"font_size_2xl"}
            />
          </div>

          <div className="setting-screen-main-container p-l-15 m-l-20">
            <div className="m-t-15 full-width">
              <CustomTextInput
                type="name"
                placeholder="Name"
                value={memberName}
                onChange={(e) => setMemberName(e.target.value)}
                onBlur={(e) => validateName(e.target.value)}
              />
              <h5 className="error">{error.member_name}</h5>
            </div>
            <div className="m-t-15 full-width">
              <CustomTextInput
                placeholder="Enter Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onBlur={(e) => validateEmail(e.target.value)}
              />
              <h5 className="error">{error.email}</h5>
            </div>
            {/* <div style={{ width: 369 }} className="m-t-15 ">
              <Select
                multi={false}
                options={Object.values(countryList)}
                values={countrySelected}
                onChange={(value) => updateCounty(value)}
                placeholder="Select County"
                labelField="name"
                valueField="country_code"
                autoFocus={false}
                color="#c8c2c2"
                // Customcolor
                style={{
                  border: "1px solid #b3b3b6",
                  backgroundColor: "#fff",
                  borderRadius: 8,
                  paddingBottom: "16px",
                  paddingTop: "16px",
                  width: "369px",
                  option: {
                    backgroundColor: "red",
                    width: "369px",
                  },
                }}
              />

              <h5 className="error">{error.member_country}</h5>
            </div> */}
            <div style={{ width: 369 }} className="m-t-15 whatsapp-number">
              {/* <CustomTextInput
                placeholder="Enter Whatsapp Number"
                value={number}
                type="number"
                onChange={(e) => setNumber(e.target.value)}
                onBlur={(e) => validateNumber(e.target.value)}
              /> */}
              <PhoneInput
                defaultCountry="IN"
                value={number}
                onChange={setNumber}
                placeholder="Enter phone number"
              />
            </div>
            <div className="m-t-15 full-width">
              <PrimaryCta
                fontSize={"font_size_large"}
                fullWidth={true}
                text={"Send Invite"}
                isLoading={loading}
                onClick={() => {
                  postInviteMembers(updateProgress);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InviteMember;
