import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Icon from "../../components/core_component/icon";
import {
  CustomText,
  PrimaryCta,
  Image,
  Video,
  CustomButton,
  FallBackBtn,
} from "../../components";
import Utils from "../../common/utils";
import ReactHtmlParser from "react-html-parser";
import CreateCampaignSuccess from "./createCampaignSuccess";
import { useSelector } from "react-redux";
import Core from "../../common/clientSdk";
import { ToastContainer, toast } from "react-toastify";
import { Loader } from "../loader";
import CustomTable from "../../components/customTable";
import Tooltip from "@mui/material/Tooltip";
import * as CampaignData from "../../common/data/campaign";
import { useNavigate } from "react-router-dom";

function CampaignBestMatchInfluencer(props) {
  console.log("ssssss props", props);
  const [isLoading, setIsLoading] = useState(false);
  const [bestMatchInfluencer, setBestMatchInfluencer] = useState([]);
  const [influencerId, setInfluencerId] = useState(null);
  const [hoveredIcons, setHoveredIcons] = useState([]);
  const [isInviteLoading, setIsInviteLoading] = useState({});
  const [shortlistedInfluencer, setShortlistedInfluencer] = useState([]);
  const collectionId =
    props?.createCampaignResponse?.campaign_data?.collection_id;
  const campaignId = props?.createCampaignResponse?.campaign_data?.campaign_id;

  const navigate = useNavigate();
  useEffect(() => {
    getSuggestedInfluencerList();
  }, []);

  function updateProgress(status) {
    setIsLoading(status);
  }
  const redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { BrandUser = {} } = redux_selector;
  const { brand_deep_data = {} } = BrandUser;
  const { user_data = {} } = BrandUser;

  function getSuggestedInfluencerList() {
    var params = {
      campaign_id: campaignId,
      start: 0,
    };
    Core.getSuggestedInfluencerList(
      getSuggestedInfluencerListSuccess,
      getSuggestedInfluencerListFailed,
      updateProgress,
      params
    );
  }

  function getSuggestedInfluencerListSuccess(response_data) {
    setBestMatchInfluencer(response_data.suggested_list);

    if (response_data?.suggested_list?.length == 0) {
      navigate(`/campaigns/${campaignId}`, {
        state: { data: "invite-page" },
      });
    }
  }

  function getSuggestedInfluencerListFailed(errorList) {
    toast.error(errorList);

    setIsLoading(false);
  }

  function postAddInfluencerCollection(influencer_id) {
    const params = {
      collection_id: collectionId,
      influencer_id: influencer_id,
    };
    Core.postAddInfluencerCollection(
      postAddInfluencerCollectionSuccess,
      postAddInfluencerCollectionFailed,
      (status) => setIsInviteLoading({ [influencer_id]: status }),
      params
    );
  }

  function postAddInfluencerCollectionSuccess(response_data) {
    console.log("response_data d", response_data);
    setShortlistedInfluencer((prev) => [...prev, response_data]);

    toast.success("Influencer Added List Successfully");
  }

  function disableShortListedBtn(influencerId) {
    const isShortlisted = shortlistedInfluencer.some(
      (item) => item?.influencer_collection_data?.influencer_id === influencerId
    );

    return isShortlisted;
  }

  function postAddInfluencerCollectionFailed(errorList) {
    toast.error(errorList.map((item) => item.m).join(", "));
  }

  function getInflencerLocation(influencer_data) {
    if (influencer_data["city_id"]) {
      return CampaignData.CITY.find(
        (city) => city.id == influencer_data["city_id"]
      )?.label;
    } else if (influencer_data["state"]) {
      return CampaignData.LOCATIONS[influencer_data["state"]]?.n;
    } else {
      return "-";
    }
  }
  console.log("shortlistedInfluencer", shortlistedInfluencer);

  const platFormName = (platforms_data, influencer_id) => {
    console.log("platforms_data", platforms_data);

    const handleMouseEnter = (index) => {
      const newHoveredIcons = [...hoveredIcons];
      newHoveredIcons[index] = true;
      setHoveredIcons(newHoveredIcons);
    };

    const handleMouseLeave = (index) => {
      const newHoveredIcons = [...hoveredIcons];
      newHoveredIcons[index] = false;
      setHoveredIcons(newHoveredIcons);
    };

    const handlePlatformClick = (url) => {
      // Open the platform URL here
      window.open(url, "_blank");
      // Reset all hover states to false after clicking
      setHoveredIcons(new Array(platforms_data.length).fill(false));
    };

    return (
      <>
        {platforms_data.length > 0 && platforms_data[0].platform_id != null && (
          <div>
            {/* <CustomText
              p
              text={"Social Presence"}
              fontSize={"font_size_small"}
              textColor={"text_color_black"}
              fontWeight={"Poppins_semiBold"}
            /> */}
          </div>
        )}
        <div style={{ marginTop: 2 }} className="flex-row">
          <div
            className={"platform-name-collection"}
            data-tooltip-id={`tooltip-${influencer_id}${platforms_data[0].platform_id}`}
            onMouseEnter={() =>
              handleMouseEnter(
                `${influencer_id}${platforms_data[0].platform_id}`
              )
            }
            onMouseLeave={() =>
              handleMouseLeave(
                `${influencer_id}${platforms_data[0].platform_id}`
              )
            }
            onClick={() =>
              handlePlatformClick(
                Utils.getSocialUrl(
                  platforms_data[0].platform_id,
                  platforms_data[0].identifier_id
                )
              )
            }
          >
            <CustomText
              p
              text={"@" + platforms_data[0].identifier_id}
              className="caption_2"
              textColor={"text_color_blue_500"}
            />
          </div>
          {/* {platforms_data.map((item, index) => {
            if (Utils.getSocialIcon(item.platform_id)) {
              return (
                <>
                  <div
                    key={index}
                    className={"platform-name"}
                    data-tooltip-id={`tooltip-${influencer_id}${item.platform_id}`}
                    onMouseEnter={() =>
                      handleMouseEnter(`${influencer_id}${item.platform_id}`)
                    }
                    onMouseLeave={() =>
                      handleMouseLeave(`${influencer_id}${item.platform_id}`)
                    }
                    onClick={() =>
                      handlePlatformClick(
                        Utils.getSocialUrl(item.platform_id, item.identifier_id)
                      )
                    }
                  >
                    <Icon
                      iconName={
                        hoveredIcons[`${influencer_id}${item.platform_id}`]
                          ? Utils.getSocialIconHover(item.platform_id)
                          : Utils.getSocialIcon(item.platform_id)
                      }
                      height={14}
                      width={14}
                    />

                    <CustomText
                      p
                      text={item.identifier_id}
                      className="caption_2"
                    />
                  </div>
                  <ReactTooltip
                    style={{ zIndex: 20 }}
                    id={`tooltip-${influencer_id}${item.platform_id}`}
                    place="bottom"
                    content={item.identifier_id}
                  />
                </>
              );
            }
            return null;
          })} */}
        </div>
      </>
    );
  };

  const getSelectedPlatformData = (platforms_data) => {
    return platforms_data?.find((item) => item.platform_id === "btll");
  };

  const formatPlatformData = (platforms_data, key) => {
    const selectedPlatformData = getSelectedPlatformData(platforms_data);
    return Utils.changeNumberFormate(selectedPlatformData?.[key]);
  };

  const influencerTableSchema = [
    {
      name: "Influencer Name",
      selector: (row) => row["influencer_data"]["name"],

      cell: (data) => (
        <span
          onClick={(e) => {
            // toggleFlyoutDropdown();
            // setInfluencerId(data?.influencer_data.influencer_id);
          }}
          style={{ display: "flex", gap: 8, alignItems: "center" }}
        >
          <Image
            source={
              data.influencer_data.profile_picture
                ? Utils.generateInfluencerDpUrl(
                    data.influencer_data.influencer_id,
                    data.influencer_data.profile_picture
                  )
                : require("../../resources/images/user-copy.png")
            }
            className="proposal-user-profile"
          />
          <div style={{ flexDirection: "row", display: "flex", gap: 5 }}>
            <div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  gap: 5,
                  alignItems: "baseline",
                }}
              >
                <CustomText
                  p
                  text={data.influencer_data.name}
                  className="Body-1"
                />
                {/* <div>
                  <CustomText
                    p
                    text={`(${data.influencer_data.ratings.toFixed(1)})`}
                    className="typographyH5"
                  />
                </div> */}
              </div>
              {/* <CustomText
                p
                text={getInflencerLocation(data?.influencer_data)}
                fontSize={"font_size_extraSmall"}
                fontWeight={"rubik_regular"}
                textColor={"text_color_primary"}
                textAlign={"text_align_left"}
              /> */}
              {platFormName(
                data.platforms_data,
                data.influencer_data.influencer_id
              )}
            </div>
          </div>
        </span>
      ),
      width: "250px",
    },
    // {
    //   name: "Platform",
    //   selector: (row) =>
    //     platFormName(row.platforms_data, row.influencer_data.influencer_id),
    //   // sortable: true,
    //   width: "100px",
    // },
    {
      name: "Followers",
      selector: (row) => formatPlatformData(row.platforms_data, "followers"),

      width: "150px",
    },
    // {
    //   name: "Like",
    //   selector: (row) => formatPlatformData(row.platforms_data, "avg_likes"),
    //   width: "90px",
    // },
    // {
    //   name: "Comment",
    //   selector: (row) => formatPlatformData(row.platforms_data, "avg_comments"),
    //   width: "90px",
    // },
    {
      name: "Avg Views",
      selector: (row) =>
        Utils.isObjectOrNullUndefinedZero(
          formatPlatformData(row.platforms_data, "avg_views")
        )
          ? "-"
          : formatPlatformData(row.platforms_data, "avg_views"),
      width: "100px",
    },
    {
      name: "ER",
      selector: (row) =>
        Utils.isNullOrUndefinedOrZero(
          formatPlatformData(row.platforms_data, "engagement_rate")
        )
          ? "-"
          : formatPlatformData(row.platforms_data, "engagement_rate"),
      width: "130px",
      cell: (data) => (
        <span>
          <Tooltip
            title={
              <>
                <div>
                  Avg Likes ={" "}
                  {formatPlatformData(data.platforms_data, "avg_likes")}
                </div>
                <div>
                  Avg Comments ={" "}
                  {formatPlatformData(data.platforms_data, "avg_comments")}
                </div>
              </>
            }
          >
            {Utils.isNullOrUndefinedOrZero(
              Number(formatPlatformData(data.platforms_data, "engagement_rate"))
            )
              ? "-"
              : Number(
                  formatPlatformData(data.platforms_data, "engagement_rate")
                ).toFixed(2) + " %"}
          </Tooltip>
        </span>
      ),
    },

    {
      name: "",
      lastCol: true,
      paddingRight: "20px",
      conditionalCellStyles: [
        {
          when: (row) => true,
          style: {
            justifyContent: "right",
          },
        },
      ],
      cell: (data) => (
        <snap
          style={{
            display: "flex",
            gap: 24,
            alignItems: "center",
          }}
        >
          <CustomButton
            onClick={() => {
              postAddInfluencerCollection(data.influencer_data.influencer_id);
            }}
            text={
              disableShortListedBtn(data?.influencer_data?.influencer_id)
                ? "Shortlisted"
                : "Shortlist"
            }
            fontSize={"font_size_small"}
            isLoading={isInviteLoading[data?.influencer_data?.influencer_id]}
            borderRadius={4}
            disabled={disableShortListedBtn(
              data?.influencer_data?.influencer_id
            )}
            width={"130px"}
            padding={{ top: 12, right: 12, bottom: 12, left: 12 }}
            textColor={"text_color_light"}
            background={
              disableShortListedBtn(data?.influencer_data?.influencer_id)
                ? "#B3B3B6"
                : "#090A14"
            }
            border={"none"}
            boxShadow={"none"}
            hoverColor={"var(--hover-color, #1977F2)"}
          />
          {/* <div
            onClick={() => {
              postAddInfluencerCollection(data.influencer_data.influencer_id);
            }}
            style={{ textWrap: "nowrap", fontSize: "bold" }}
          >
            <CustomText
              p
              bold
              text={"Shortlist"}
              fontWeight={"rubik_regular"}
              textColor={"text_color_secondary"}
            />
          </div> */}
        </snap>
      ),

      // sortable: true,
    },
  ];

  function renderFallBack() {
    return (
      <>
        <FallBackBtn heading={"No influencer available"} />
      </>
    );
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Modal
          isOpen={true}
          onRequestClose={props.closeModal}
          style={customStyles}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              background: "#ffffff",

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 60,
              paddingBottom: 10,
            }}
          >
            <div
              onClick={() =>
                navigate(`/campaigns/${campaignId}`, {
                  state: { data: "invite-page" },
                })
              }
              className="close-btn-icon"
            >
              <Icon iconName="CloseRoundButtonIcon" />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CustomText
                p
                text={"Suggested influencers"}
                textColor={"text_color_primary"}
                fontSize={"font_size_2xl"}
                className="typographyH7 m-b-5"
              />
              <CustomText
                p
                text={
                  "We found some matching influencers, shortlist them to get pricing quickly"
                }
                textColor={"text_color_secondary"}
                fontSize={"font_size_label"}
                className="typographyH5"
              />
            </div>
          </div>

          <>
            {bestMatchInfluencer.length !== 0 ? (
              <div className="custom-table-bid m-t-60">
                <CustomTable
                  className="CustomTable"
                  tableSchema={influencerTableSchema}
                  // paginationPerPage={5}
                  tableData={bestMatchInfluencer}
                  rowPaddingTop={16}
                  rowPaddingBottom={16}
                  customStyles={customBidStyles}
                  progressComponent={
                    <div className="fall-back-center-campaign">
                      <Loader />
                    </div>
                  }
                  // pagination
                />
              </div>
            ) : (
              <>{renderFallBack()}</>
            )}
          </>

          {/* <span
          onClick={() =>
            navigate(`/campaigns/${campaignId}`, {
              state: { data: "invite-page" },
            })
          }
          className="see-more-text see-more-container"
        >
          ... See More
        </span> */}
          <div className="review-campaign-btn-wrap">
            <PrimaryCta
              fifth
              onClick={() =>
                navigate(`/campaigns/${campaignId}`, {
                  state: { data: "invite-page" },
                })
              }
              textAlign={"text_align_center"}
              fontSize={"font_size_large"}
              text={"See More"}
              className={"review-submit-btn"}
            />
          </div>
        </Modal>
      )}
    </>
  );
}

export default CampaignBestMatchInfluencer;

const customBidStyles = {
  headRow: {
    style: {
      backgroundColor: "var(--general-secondary-color)", // Change header background color
      color: "var(--gray-gray-300)", // Change header text color
      fontSize: "14px",
      fontWeight: 600,
      lineHight: "19.2px",
      border: "none",
    },
  },
  headCells: {
    style: {
      padding: "10px 0px 10px 20px",
    },
  },
  rows: {
    style: {
      cursor: "pointer",
      fontSize: "12px",
      paddingTop: "5px",
      paddingBottom: "5px",
      fontWeight: 400,
      lineHight: "19.2px",
      "&:hover": {
        backgroundColor: "var(--general-secondary-color)",
        fontWeight: 600,
      },
    },
  },
  cells: {
    style: {
      paddingLeft: "20px",
    },
  },
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--bg-white-color)",
    // borderColor: "var( --blue-blue-500)",

    zIndex: "5",
    height: "90%",
    display: "flex",
    flexDirection: "column",

    width: "1000px",

    padding: "0px",
    paddingBottom: "20px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.86)",
    zIndex: 10,
  },
};
