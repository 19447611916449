import { useState } from "react";
import { CustomButton, Image, PrimaryCta } from "../components";
import { useNavigate } from "react-router-dom";

const HeaderShare = () => {
  const navigate = useNavigate();
  return (
    <div className="header ">
      <div className="header-left">
        <div
          //   onClick={() => navigate("/")}
          className="header-logo cursor-pointer">
          <Image
            source={"../../assets/images/reelax-new-logo.svg"}
            height="40px"
          />
        </div>
      </div>

      <div className="header-right">
        <div className="create-campaign-button">
          <CustomButton
            onClick={() =>
              (window.location.href = "https://calendly.com/reelax/onboarding")
            }
            fontSize={"font_size_small"}
            text={"Request demo"}
            borderRadius={4}
            padding={{ top: 8, right: 18, bottom: 8, left: 18 }}
            textColor={"text_color_light"}
            background={"#1777f1"}
            border={"none"}
            boxShadow={"none"}
            hoverColor={"var(--hover-color, #1977F2)"}
          />
        </div>
      </div>
    </div>
  );
};

export default HeaderShare;
