import React, { useState, lazy, Suspense } from "react";
import { useNavigate } from "react-router-dom";
import {
  CustomText,
  Image,
  CustomTextInput,
  PrimaryCta,
  LinkCta,
  UnderLinedLinkCta,
} from "../../components";
import Header from "../header";
const ProposalPolicy = function (props) {
  return (
    <div>
      <Header/>
    <div className="main-container " style={{paddingTop:"70px"}}>
      <div className="text-screen-container">
        <h1 style={{marginBottom:"30px"}}><center>Reelax Contract Cancellation Terms</center></h1>
        <p style={{marginTop:"10px"}}>
        To maintain fairness and transparency for both brands and influencers, Reelax outlines the following contract cancellation terms:
        </p>
        <h3>1. Cancellation Before Work Starts</h3>
        <div style={{paddingLeft:"40px"}}>
        <p style={{marginTop:"10px"}}>
          <ul >
            <li >
              <b>Scenario:</b> The brand assigns the contract, and the influencer does not begin working.
           </li>
            <li>
               <b> Policy:</b> 100% cancellation is offered, and the full contract amount is refunded to the brand.
          </li>
          </ul>
        </p>
        </div>
        <h3>2. Cancellation After Work Starts</h3>
        <div style={{paddingLeft:"40px"}}>
        <p style={{marginTop:"10px"}}>
          <ul >
            <li >
            <b>Scenario:</b> The influencer marks the contract as “Work Started” but has not submitted the content.
           </li>
          </ul>
          <div style={{paddingLeft:"20px"}} >
          <ul type="circle">
          <li>
            <b>Influencer’s Fault (Delayed Submission):</b> A 10% cancellation fee is applied, and the remaining amount is refunded to the brand.
          </li>
          <li>
            <b>Influencer Not Responding:</b> 100% cancellation is offered, and the full amount is refunded to the brand.
          </li>
          <li>
            <b>Influencer Working but Brand Denies:</b> A 50% cancellation fee is applied, and the remaining amount is refunded to the brand.
          </li>
          </ul>
          </div>
        </p>
        </div>
        <h3>3. Cancellation After Content Submission</h3>
        <div style={{paddingLeft:"40px"}}>
        <p style={{marginTop:"10px"}}>
          <ul >
            <li >
            <b>Scenario:</b> The influencer submits the content, and the brand does not approve it within 15 days.
           </li>
          </ul>
          <div style={{paddingLeft:"20px"}} >
          <ul type="circle">
          <li>
            <b>Policy:</b> If the brand takes no action within 15 days, the content will be automatically approved.
          </li>
          </ul>
          </div>
        </p>
        <p style={{marginTop:"10px"}}>
          <ul >
            <li >
            <b>Scenario:</b> The influencer submits the content, and the brand does not approve it within 15 days.
           </li>
          </ul>
          <div style={{paddingLeft:"20px"}} >
          <ul type="circle">
          <li>
            <b>If the Content Follows Instructions:</b> The brand can request minor fixes only. Retakes are not permitted.
          </li>
          </ul>
          </div>
        </p>
        <p style={{marginTop:"10px"}}>
          <ul >
            <li >
            <b>If the Content Violates Brand Guidelines:</b>
           </li>
          </ul>
          <div style={{paddingLeft:"20px"}} >
          <ul type="circle">
          <li>
          The influencer must either retake the content or agree to a cancellation with a 10% cancellation fee applied to the influencer.
          </li>
          <li>
            <b>If the Brand Declines Retake:</b> The brand may cancel the contract with a 50% cancellation fee applied.
          </li>
          </ul>
          </div>
        </p>
        </div>
        
        <div style={{marginTop:"30px"}}>These terms ensure clarity and protect both parties in case of disputes or changes during the collaboration.</div>
      </div>
    </div>
    </div>
  );
};
export default ProposalPolicy;
