import React, { useState, useEffect } from "react";
import { CustomButton, Image, PrimaryCta } from "../../components";
import {
  requestPermission,
  onMessageListener,
  messaging,
  getNotificationToken,
} from "../../common/firebase.js";
import WebStorage from "../../common/web_storage";
var Notification = window.Notification || {};

const NotificationPrompt = () => {
  const [permission, setPermission] = useState(Notification.permission);
  const [deferred, setDeferred] = useState(false);

  useEffect(() => {
    setPermission(Notification.permission);
    setDeferred(WebStorage.getDataFromWebStorage("NOTIFICATION_POP"));
  }, []);

  const handleAllow = () => {
    // Check if the Notification API is supported in the browser
    if (!("Notification" in window)) {
      console.error("This browser does not support notifications.");
      return;
    }

    if ("Notification" in window && "PushManager" in window && Notification) {
      Notification.requestPermission()
        .then((permission) => {
          setPermission(permission);

          if (permission === "granted") {
            requestPermission(); // Assuming this is a custom function
          }
          setDeferred(true);
        })
        .catch((error) => {
          console.error("Error requesting notification permission:", error);
        });
    } else {
      console.error("This browser does not support push messaging.");
    }
  };

  const handleLater = () => {
    setDeferred(true);
    WebStorage.saveDataInWebStorage("NOTIFICATION_POP", true);
  };

  if (permission === "granted" || deferred) {
    return null; // Don't show the prompt if permission is granted or deferred
  }

  console.log("notification prompt");

  return (
    <div className="notification-prompt">
      <div style={{ display: "flex", flexDirection: "row" }}>
        <p>Get notifications on important updates from Influencers</p>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <button className="cancel-btn-notification " onClick={handleLater}>
          Later
        </button>
        <button className="allow-btn-notification" onClick={handleAllow}>
          Allow
        </button>
      </div>
    </div>
  );
};

export default NotificationPrompt;
