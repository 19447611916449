import React, { useState, useEffect } from "react";
import {
  Image,
  Video,
  PrimaryCta,
  CustomButton,
  CustomText,
  ContractShareListDropdown,
  InfluencerListDropdown,
  FallBack,
  FallBackBtn,
} from "../../components";
import Modal from "react-modal";
import Core from "../../common/clientSdk";
import Utils from "../../common/utils";
import { useLocation, useParams } from "react-router-dom";
import Icon from "../../components/core_component/icon";
import { toast, ToastContainer } from "react-toastify";
import { InstagramEmbed, YouTubeEmbed, XEmbed } from "react-social-media-embed";
import { useSearchParams, useNavigate } from "react-router-dom";
import HeaderShare from "../../modal/HeaderShare";
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
const contractStatusList = [
  { label: "ALL", id: "ALL" },
  { label: "ACTIVE", id: "ACTIVE" },
  { label: "COMPLETED", id: "COMPLETED" },
  { label: "CANCELLED", id: "CANCELLED" },
  { label: "IN PROGRESS", id: "IN_PROGRESS" },
];
const ShareContracts = () => {
  const [openRow, setOpenRow] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [next, setNext] = useState(10);
  const [filterStatus, setFilterStatus] = useState("ALL");
  const [influencerContractsShare, setInfluencerContractsShare] = useState([]);
  const [originalContractsShare, setOriginalContractsShare] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    getInfluencerContractsListShareData();
  }, []);
  useEffect(() => {
    const filterAssignment = originalContractsShare.filter((item) => {
      if (filterStatus == "ALL") {
        return true;
      }
      return item.assignment_data.assignment_status == filterStatus;
    });
    setInfluencerContractsShare(filterAssignment);
  }, [filterStatus]);

  console.log("influencerContractsShare", influencerContractsShare);
  useEffect(() => {
    const params = new URLSearchParams();
    if (filterStatus !== "ALL") {
      params.set("label", filterStatus);
    }
    navigate({ search: params.toString() });
  }, [filterStatus]);

  const { share_key } = useParams();

  console.log("Params", useParams());

  function getInfluencerContractsListShareData() {
    var params = {
      share_key: share_key,
    };
    Core.getInfluencerContractsListShareData(
      getInfluencerContractsListShareDataSuccess,
      getInfluencerContractsListShareDataFailed,
      () => {},
      params
    );
  }
  function getInfluencerContractsListShareDataSuccess(response) {
    setInfluencerContractsShare(
      response?.assignment_deep_data?.assignment_list
    );
    setOriginalContractsShare(response?.assignment_deep_data?.assignment_list);
  }
  function getInfluencerContractsListShareDataFailed(error) {
    console.log("error", error);
    toast.error(error.map((item) => item.m).join(","));
  }

  const openModal = (item) => {
    setCurrentItem(item);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentItem(null);
  };

  const openTable = (index) => {
    setOpenRow((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  function getAssignmentStatus(assignment_status, item) {
    console.log("item", item);

    if (assignment_status == "COMPLETED") {
      return (
        <CustomText p text={"COMPLETED"} textColor="text_completed_color" />
      );
    } else if (assignment_status == "CANCELLED") {
      return <CustomText p text={"Cancelled"} textColor="text_debit_color" />;
    } else if (assignment_status == "IN_PROGRESS") {
      return (
        <CustomText p text={"In Progress"} textColor="text_completed_color" />
      );
    } else if (assignment_status == "ACTIVE") {
      return <CustomText p text={"Active"} textColor="text_completed_color" />;
    }
  }

  function assignmentPreview(item) {
    console.log(
      "kkk",
      item,
      Object.values(item?.contents_deep_data)[0]?.content_data.status
    );

    if (
      Object.values(item?.contents_deep_data)[0]?.content_data?.status ==
        "REWORK" ||
      Object.values(item?.contents_deep_data)[0]?.content_data?.status ==
        "NEW" ||
      Object.values(item?.contents_deep_data)[0]?.content_data?.status ==
        "CONTENT_PENDING"
    ) {
      return <CustomText p text={"-"} />;
    }
    return (
      <CustomButton
        onClick={() => openModal(item)}
        fontSize={"font_size_small"}
        width={146}
        height={30}
        text={"Preview"}
        borderRadius={4}
        padding={{ top: 6, right: 12, bottom: 6, left: 12 }}
        textColor={"text_color_light"}
        background={"#090A14"}
        border={"none"}
        boxShadow={"none"}
        hoverColor={"var(--hover-color, #1977F2)"}
      />
    );
  }

  function socialMedialEmbed(item) {
    // console.log("item4",Object.values( item?.contents_deep_data)[0].content_data.content_type_id)
    const platform = Utils.socialMediaPlatform(
      Object.values(item?.contents_deep_data)[0]?.content_data?.content_type_id
    );

    const mediaType =
      Object.values(item?.contents_deep_data)[0]
        ?.content_data?.media_id?.split(".")
        .pop() === "mp4";

    return (
      <>
        {Object.values(item?.contents_deep_data)[0]?.content_data?.url && (
          <div className="embed-container">
            {platform === "Instagram" && (
              <InstagramEmbed
                url={
                  Object.values(item?.contents_deep_data)[0]?.content_data?.url
                }
                width={325}
              />
            )}
            {platform === "youtube" && (
              <YouTubeEmbed
                url={
                  Object.values(item?.contents_deep_data)[0]?.content_data?.url
                }
                width={325}
                height={620}
              />
            )}
            {platform === "twitter" && (
              <XEmbed
                url={
                  Object.values(item?.contents_deep_data)[0]?.content_data?.url
                }
                width={325}
              />
            )}
          </div>
        )}

        {Object.values(item?.contents_deep_data)[0]?.content_data?.media_id &&
          mediaType &&
          !Object.values(item?.contents_deep_data)[0]?.content_data?.url && (
            <div className="download-content">
              <Video
                source={Utils.constructContentMediaUrl(
                  item.assignment_data?.assignment_id,
                  Object.values(item?.contents_deep_data)[0]?.content_data
                    ?.media_id
                )}
                width={300}
                height={420}
                className="content-media"
              />
              <PrimaryCta
                fifth
                onClick={() =>
                  handleDownload(
                    item?.assignment_data?.assignment_id,
                    true,
                    Object.values(item?.contents_deep_data)[0]?.content_data
                      ?.media_id
                  )
                }
                fontSize={"font_size_large"}
                text={"Download"}
              />
            </div>
          )}

        {Object.values(item?.contents_deep_data)[0]?.content_data?.media_id &&
          !mediaType &&
          !Object.values(item?.contents_deep_data)[0]?.content_data?.url && (
            <div className="download-content">
              <Image
                source={Utils.constructContentMediaUrl(
                  item?.assignment_data?.assignment_id,
                  Object.values(item?.contents_deep_data)[0]?.content_data
                    ?.media_id
                )}
                width={300}
                height={420}
                className="content-media"
              />
              <PrimaryCta
                fifth
                onClick={() =>
                  handleDownload(
                    Object.values(item?.contents_deep_data)[0]?.content_data
                      ?.media_id,
                    false,
                    item?.assignment_data?.assignment_id
                  )
                }
                fontSize={"font_size_large"}
                text={"Download"}
              />
            </div>
          )}
      </>
    );
  }

  const handleDownload = (media_id, isVideo, assignment_id) => {
    const mediaUrl = Utils.constructContentMediaUrl(assignment_id, media_id);

    // Create a new window to open the URL, triggering the download
    const newWindow = window.open(mediaUrl, "_blank");

    if (newWindow) {
      newWindow.focus();
    } else {
      // For cases where the popup blocker prevents opening a new window
      alert("Please allow popups for this website");
    }
  };

  //console.log("ContractsShare",data)

  return (
    <>
      {" "}
      <div>
        <HeaderShare />
      </div>
      <div style={{ paddingTop: 90 }} className="share">
        <div className="contractsharelist-header">
          <InfluencerListDropdown
            dropdownItems={contractStatusList}
            onItemClick={(item) => {
              setNext(0);
              setFilterStatus(item);
            }}
          />
        </div>
        {influencerContractsShare.length !== 0 ? (
          <table class="custom_table bordered">
            <thead>
              <tr>
                <th data-label="" className="share-influencer-table-heading">
                  Influencer Details
                </th>

                <th data-label>Deliverables</th>

                <th data-label>Status</th>
                <th data-label></th>
              </tr>
            </thead>
            <tbody>
              {influencerContractsShare &&
                influencerContractsShare.map((item, index) => {
                  return (
                    <tr
                      class={openRow[index] ? "close" : "open"}
                      key={index}
                      onClick={() => openTable(index)}
                    >
                      <td class="tr-1 " data-label="">
                        {item?.influencer_data?.name}
                      </td>

                      {/* <td className="table-column-data" data-label="Rewards">
                      {item?.reward_data?.value?.amount
                        ? `Rs.${item?.reward_data?.value?.amount}`
                        : "-"}
                    </td> */}

                      <td
                        className="table-column-data"
                        data-label="Deliverables"
                      >
                        {Utils.generateAssignmentSubText(item)}
                      </td>

                      <td className="table-column-data" data-label="Status">
                        <span>
                          {item?.assignment_data?.assignment_status ? (
                            getAssignmentStatus(
                              item?.assignment_data?.assignment_status,
                              item
                            )
                          ) : (
                            <div></div>
                          )}
                        </span>
                      </td>
                      <td className="table-column-data" data-label="Status">
                        <span>
                          {item?.assignment_data?.assignment_status ? (
                            assignmentPreview(item)
                          ) : (
                            <div></div>
                          )}
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              overflow: "hidden",
            }}
          >
            <FallBack
              heading={"No Contracts Available"}
              // title={"Explore more and short out some items"}
            />
          </div>
        )}

        <Modal
          style={CancelReminder}
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
        >
          <div onClick={closeModal} className="close-btn-icon">
            <Icon iconName="CloseRoundButtonIcon" />
          </div>
          {currentItem && socialMedialEmbed(currentItem)}
        </Modal>
        <ToastContainer />
      </div>
    </>
  );
};
export default ShareContracts;

const CancelReminder = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    background: "var(--general-secondary-color)",
    textAlign: "center",
    zIndex: "5",
    height: "40%",
    display: "flex",
    flexDirection: "column",
    minWidth: "300px",
    minHeight: "500px",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.95)",
    zIndex: 10,
  },
};
